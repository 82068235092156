@keyframes fade-in-flex {
    0% {
        display: flex;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-slide {
    0% {
        display: block;
        margin-top: -15px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translateX(-15px);
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        height: auto;
        padding-top: 5px;
        padding-bottom: 15px;
    }
}


@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
