$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-info: "\e927";
$icon-wi-fi: "\e92d";
$icon-table-tennis: "\e92e";
$icon-chef: "\e92f";
$icon-bubbles: "\e930";
$icon-gym: "\e931";
$icon-protect: "\e932";
$icon-tennis: "\e933";
$icon-water-heater: "\e934";
$icon-swimming: "\e938";
$icon-air: "\e939";
$icon-toilet: "\e942";
$icon-bed: "\e943";
$icon-plus-fat: "\e923";
$icon-calendar-time: "\e929";
$icon-label: "\e92a";
$icon-calendar: "\e92b";
$icon-icons8-collapse_arrow: "\e924";
$icon-icons8-expand_arrow: "\e925";
$icon-link: "\e916";
$icon-delete: "\e917";
$icon-icons8-right_4: "\e918";
$icon-trash: "\e919";
$icon-people: "\e91a";
$icon-museum: "\e91b";
$icon-cocktail: "\e91e";
$icon-surfing: "\e91f";
$icon-golf: "\e920";
$icon-tableware: "\e921";
$icon-house: "\e922";
$icon-limetravel-logo: "\e915";
$icon-arrow-down-trigger: "\e914";
$icon-drag-list-up: "\e911";
$icon-star: "\e90d";
$icon-star-half: "\e90f";
$icon-star-outlined: "\e910";
$icon-linkedin: "\e90a";
$icon-twitter: "\e90b";
$icon-facebook: "\e90c";
$icon-checkmark-circle: "\e901";
$icon-arrow-left-circle: "\e903";
$icon-arrow-right-circle: "\e906";
$icon-plus-small: "\e907";
$icon-arrow-right: "\e908";
$icon-arrow-left: "\e909";
$icon-play: "\e900";
$icon-quote: "\e941";
$icon-pdf: "\e926";
$icon-zip: "\e93a";
$icon-txt: "\e93b";
$icon-doc: "\e93c";
$icon-jpg: "\e93d";
$icon-tif: "\e93e";
$icon-png: "\e93f";
$icon-ppt: "\e940";
$icon-youtube: "\e935";
$icon-pinterest: "\e936";
$icon-instagram: "\e937";
$icon-plus: "\e902";
$icon-arrow-down: "\e904";
$icon-arrow-up: "\e905";
$icon-checkmark: "\e90e";
$icon-chevron-up: "\e91c";
$icon-chevron-down: "\e91d";
$icon-chevron-left: "\e912";
$icon-chevron-right: "\e913";
$icon-mail: "\e928";
$icon-play-circle: "\e92c";

