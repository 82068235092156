$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px
);

.padding-bottom--xsmall {
  @include get-variable-attribute('padding-bottom', (
    null: 20px,
    small: 20px,
    medium: 25px,
    large: 30px)
  );
}

.padding-bottom--small {
  @include get-variable-attribute('padding-bottom', (
    null: 25px,
    small: 20px,
    medium: 30px,
    large: 35px)
  );
}

.padding-bottom--medium {
  @include get-variable-attribute('padding-bottom', (
    null: 30px,
    small: 35px,
    medium: 40px,
    large: 45px
  ));
}

.padding-bottom--large {
  @include get-variable-attribute('padding-bottom', (
    null: 50px,
    small: 55px,
    medium: 60px,
    large: 65px
  ));
}

.padding-bottom--xlarge {
  @include get-variable-attribute('padding-bottom', (
    null: 55px,
    small: 60px,
    medium: 65px,
    large: 75px
  ));
}

.padding-top--xsmall {
  @include get-variable-attribute('padding-top', (
    null: 20px,
    small: 20px,
    medium: 25px,
    large: 30px)
  );
}

.padding-top--small {
  @include get-variable-attribute('padding-top', (
    null: 25px,
    small: 25px,
    medium: 30px,
    large: 35px)
  );
}

.padding-top--medium {
  @include get-variable-attribute('padding-top', (
    null: 30px,
    small: 35px,
    medium: 40px,
    large: 45px
  ));
}

.padding-top--large {
  @include get-variable-attribute('padding-top', (
    null: 50px,
    small: 55px,
    medium: 60px,
    large: 65px
  ));
}

.padding-top--xlarge {
  @include get-variable-attribute('padding-top', (
    null: 55px,
    small: 60px,
    medium: 65px,
    large: 75px
  ));
}

.margin-bottom--xsmall {
  @include get-variable-attribute('margin-bottom', (
    null: 20px,
    small: 20px,
    medium: 25px,
    large: 30px)
  );
}

.margin-bottom--small {
  @include get-variable-attribute('margin-bottom', (
    null: 25px,
    small: 25px,
    medium: 30px,
    large: 35px)
  );
}

.margin-bottom--medium {
  @include get-variable-attribute('margin-bottom', (
    null: 30px,
    small: 35px,
    medium: 40px,
    large: 45px
  ));
}

.margin-bottom--large {
  @include get-variable-attribute('margin-bottom', (
    null: 50px,
    small: 55px,
    medium: 60px,
    large: 65px
  ));
}

.margin-bottom--xlarge {
  @include get-variable-attribute('margin-bottom', (
    null: 55px,
    small: 60px,
    medium: 65px,
    large: 75px
  ));
}

.margin-top--xsmall {
  @include get-variable-attribute('margin-top', (
    null: 20px,
    small: 20px,
    medium: 25px,
    large: 30px)
  );
}

.margin-top--small {
  @include get-variable-attribute('margin-top', (
    null: 25px,
    small: 25px,
    medium: 30px,
    large: 35px)
  );
}

.margin-top--medium {
  @include get-variable-attribute('margin-top', (
    null: 30px,
    small: 35px,
    medium: 40px,
    large: 45px
  ));
}

.margin-top--large {
  @include get-variable-attribute('margin-top', (
    null: 50px,
    small: 55px,
    medium: 60px,
    large: 65px
  ));
}

.margin-top--xlarge {
  @include get-variable-attribute('margin-top', (
    null: 55px,
    small: 60px,
    medium: 65px,
    large: 75px
  ));
}
