.btn {
  color: $color-two;
  background-color: $color-one;
  display: inline-block;
  padding: 13px 20px 13px 20px;
  min-width: 100px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  border-radius: 0;
  font-size: 16px;
  transition: background-color 300ms ease;
  font-family: $headings-font-family;
  font-weight: 300;
  border: none;
  box-shadow: none;
  letter-spacing: 0.8px;
  -webkit-appearance: none;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  cursor: pointer;
  min-width: 160px;

  &:active {
    background-color: $brand-primary-hover;
  }
  &:hover {
    color: $color-two;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 12px;
  }
  span {
    position: relative;
    z-index: 1;
  }
  &.btn-border {
    background: none;
    color: $color-one;
    border: 3px solid $color-one;
    padding: 10px 17px 10px 17px;
    .btn-bg {
      top: -3px;
      right: -3px;
      left: -3px;
      bottom: -3px;
    }
  }
  &:hover, &:focus {
    .btn-bg {
        &:before {
          opacity: 1;
          transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
          transform: scale(1);
        }
    }
  }
  .btn-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 50px;
    transition: transform 0.4s cubic-bezier(0.6, 0.295, 0.15, 1.645), -webkit-transform 0.4s cubic-bezier(0.6, 0.295, 0.15, 1.645);
    overflow: hidden;
    z-index: 0;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 200px;
      width: 100%;
      content: "";
      background: rgba(0,0,0,0.05);
      border-radius: 50%;
      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s,opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
      transform: scale(0);
      opacity: 0;
    }
    &:before {
      background: #21bfc1;
    }
  }
}

@each $name, $color in $colors-map {
  .btn-#{$name} {
    background-color: map-get($color, "background");
    color: map-get($color, "button-text");
    .btn-bg {
      &:before {
        background: map-get($color, "hover");
      }
    }
    &:hover {
      color: map-get($color, "button-text");
    }
  }
  .btn-border.btn-#{$name} {
    background: none;
    color: map-get($color, "background");
    border: 3px solid map-get($color, "background");
    .btn-bg {
      &:before {
        background: map-get($color, "hover");
      }
    }
    &:hover {
      color: $white;
    }
  }
}

// Link Button

// Link Button

.btn-link {
  font-size: 16px;
  font-family: $headings-font-family;
  margin-bottom: $margin;
  display: inline-block;
  font-weight: 700;
  position: relative;
  padding-right: 25px;
  color: $link-color;
  text-transform: uppercase;

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 0;
    transition: width 300ms ease;
  }

  &:hover {
    color: $link-color;
  }

  span {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }
}

@each $name, $color in $colors-map {
  .btn-link.color-#{$name} {
    color: map-get($color, "background");
    &:after {
      background: map-get($color, "background");
    }
    &:hover, &:focus, &:active {
      color: map-get($color, "background");
      &:after {
        width: 100%;
      }
    }
  }
}

.btn-icon {
  .btn {
    position: relative;
  }

  .text-container {
    display: flex;
    align-items: center;
  }

  .icon {
    position: relative;
    display: inline-block;
    z-index: 5;
    font-size: 20px;
    margin-right: 5px;
  }
}
