$breakpoints: (
        small: 480px,
        medium: 768px,
        large: 1024px,
        xlarge: 1200px
);

$h1-font-sizes: (
        null: 36px,
        small: 36px,
        medium: 40px,
        large: 46px,
        xlarge : 56px,
);

$h2-font-sizes: (
        null: 32px,
        small: 32px,
        medium: 36px,
        large: 40px,
        xlarge : 44px,
);

$h3-font-sizes: (
        null : 26px,
        small : 26px,
        medium : 30px,
        large: 36px,
        xlarge : 38px,
);

$h4-font-sizes: (
        null : 24px,
        small : 24px,
        medium : 26px,
        large: 28px,
        xlarge : 32px,
);

$h5-font-sizes: (
        null : 20px,
        small : 20px,
        medium : 22px,
        large: 22px,
        xlarge : 24px,
);

$h6-font-sizes: (
        null : 18px,
        small : 18px,
        medium : 20px,
        large: 20px,
        xlarge : 22px,
);

$ingress-font-sizes: (
        null : 22px,
        small : 24px,
        medium : 24px,
        large: 26px,
        xlarge : 26px,
);

$blockquote-font-sizes: (
        null : (16px, 24px),
        small : (16px, 24px),
        medium : (18px, 26px),
        large: (24px, 28px),
        xlarge : (32px, 40px),
);

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  color: $headings-color;
  margin-bottom: $margin;
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.3;
}

h1, .h1 {
  @include font-size($h1-font-sizes);
}

h2, .h2 {
  @include font-size($h2-font-sizes);
}

h3, .h3 {
  @include font-size($h3-font-sizes);
}

h4, .h4 {
  @include font-size($h4-font-sizes);
}

h5, .h5 {
  @include font-size($h5-font-sizes);
}

h6, .h6 {
  @include font-size($h6-font-sizes);
}

.heading-mini {
  font-size: $font-size-extra-small;
}

body {
  font-weight: $font-weight-base;
  color: $gray;
}

a {
  font-weight: $font-weight-medium;
}

hr {
  border-color: $gray;
  margin-top: $margin;
  margin-bottom: $margin;
}

p {
  margin-bottom: $margin;
  line-height: 1.4;
}

strong {
  font-weight: $font-weight-bold;
}


blockquote {
  padding: $margin;
  margin: 0 0 $margin 0;
  border: none;
  p {
    @include font-size($blockquote-font-sizes);
    letter-spacing: -0.04em;
    font-family: $headings-font-family;
    color: $color-one;

  }
}
