// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$grid-gutter-width: 30px;
$container-large-desktop: (1170px + $grid-gutter-width);

// Colors grayscale
$white: #ffffff;
$black: #000000;
$blackish: $black;
$gray-lighter: #f5f5f5;
$gray-light: #c3c4c5;
$gray: #64666B;
$gray-dark: #373a3c;
$gray-darker: #18191a;
$text-color: $gray;

// Colors brand
$tint: 10%;
$color-one: #0E3535;
$color-one-hover: darken($color-one, $tint);
$color-two: #F4D0C6;
$color-two-hover: darken($color-two, $tint);
$color-three: #009089;
$color-three-hover: darken($color-three, $tint);
$color-four: #16B3AA;
$color-four-hover: darken($color-four, $tint);
$color-five: #F1929A;
$color-five-hover: darken($color-five, $tint);
$color-six: $gray;
$color-six-hover: darken($color-six, $tint);

$brand-primary: $color-one;
$brand-primary-hover: $color-one-hover;
$brand-primary-light: lighten($brand-primary, $tint);
$brand-primary-dark: darken($brand-primary, $tint);

$brand-danger: #ea5f4a;

$link-color: $color-four;
$link-hover-color: $brand-primary;
$state-danger-text: $brand-danger;
$headings-color: #000000;

// brand
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-googleplus: #dd4b39;
$color-google: #d62d20;
$color-youtube: #bb0000;
$color-linkedin: #007bb5;
$color-instagram: #125688;
$color-whatsapp: #4dc247;
$color-pinterest: #cb2027;
$color-snapchat: #fffc00;
$color-dropbox: #007ee5;
$color-flickr: #ff0084;
$color-spotify: #00e461;
$color-vimeo: #aad450;
$color-wordpress: #21759b;

// Borders
$border-radius-base: 3px;
$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.27);

// Margins
$margin: 20px;
$gap: 30px;
$margin-bottom: $gap;

// Animation
$transitionTime: 0.25s;
$easeCurve: ease-out;

// Typography
$font-family-sans-serif: 'AustinNewsHeadline';
$headings-font-family: 'MBEmpire', sans-serif;
$font-family-demi: $font-family-sans-serif;
$font-family-bold: $font-family-sans-serif;

$font-weight-light: 300;
$font-weight-base: 300;
$font-weight-medium: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-semibold;

$link-hover-decoration: none;
$font-size-base: 19px;
$font-size-small: 18px;
$font-size-extra-small: 16px;
$line-height-normal: 26px;
$input-color-placeholder: $gray;

// Z-index positions
$tab-bar-zindex: 500;
$banner-zindex: 1000;
$nav-more-zindex: 600;

// Forms
$input-height-base: 44px;
$padding-base-horizontal: 20px;
$input-color: $brand-primary;
$input-border: $gray-light;
$placeholder: $gray-light;

// Tables
$table-cell-padding: 6px;
$table-bg: $gray-lighter;

$colors-map: (
  transparent: (background: none, hover: none, text: inherit),
  black: (background: $black, hover: lighten($black, 20%), button-text: $color-two),
  white: (background: $white, hover: darken($white, 10%), button-text: $color-one),
  gray: (background: $gray, hover: darken($gray, 10%), button-text: $color-one),
  green: (background: $color-one, hover: lighten($color-one, 10%), button-text: $color-two),
  light-green: (background: $color-three, hover: darken($color-three, 10%), button-text: $color-two),
  lighter-green: (background: $color-four, hover: darken($color-four, 10%), button-text: $white),
  pink: (background: $color-two, hover: darken($color-two, 10%), button-text: $color-one),
  light-pink: (background: $color-five, hover: lighten($color-five, 10%), button-text: $color-one)
);

//***********************************************************//

// https://codepen.io/jakealbaugh/post/css4-variables-with-fallbacks-using-sass

// color map
$color: (
        text: $color-two,
        background: $brand-primary,
);

// font map
$font: (
        family: (
                body: (Merriweather, Georgia, serif),
                head: (Roboto, Helvetica, sans-serif)
        ),
        weight: (
                body: 300,
                head: 300,
        ),
        size: (
                body: 18px,
                h1: 2.4em,
                h2: 2em,
                h3: 1.6em,
                h4: 1.4em,
        ),
        leading: (
                body: 1.7,
                head: 1.2,
        )
);

// associate variable map name to an actual variable map
$var-maps: (
        color: $color,
        font: $font
);

// which property uses which var map
// you would edit this for each property you want to use variables for
$props: (
        background-color: color,
        color: color,
        font-family: (font, family),
        font-size: (font, size),
        font-weight: (font, weight),
        line-height: (font, leading),
);

// the variable mixin takes a property and variable name
@mixin v($prop, $var, $show-fall: true) {
  // get the property's map name(s)
  $map-name: map-get($props, $prop);
  $nest-name: null;
  $nest-map-name: null;
  $map: null;
  $var-fall: null;
  $var-output: null;
  // if a list, we need to go deeper
  @if type-of($map-name) == list {
    $nest-name: nth($map-name, 1);
    $nest-map-name: nth($map-name, 2);
  }
  // if it is a nested map
  @if $nest-name {
    // get the map from nested map-name
    $map: map-get($var-maps, $nest-name);
    // get the nested map
    $nest-map: map-get($map, $nest-map-name);
    // get the var value fro mthe nested map
    $var-fall: map-get($nest-map, $var);
    $var-output: var(--#{$nest-name}-#{$nest-map-name}-#{$var});
  } @else {
    // get the map from map name
    $map: map-get($var-maps, $map-name);
    // fallback, grab the variable's value from the map
    $var-fall: map-get($map, $var);
    $var-output: var(--#{$map-name}-#{$var});
  }
  // if show standard is not overridden to be null
  @if $show-fall {
    #{$prop}: $var-fall;
  }
  // css4 variable output
  #{$prop}: $var-output;
}

// setup the css4 variable definitions
:root {
  // for each variable map
  @each $var-map-name, $var-map in $var-maps {
    // for each variable in the variable map
    @each $var, $val in $var-map {
      // if it is a map, go another level deep
      @if type-of($val) == map {
        // each in the map
        @each $var-n, $val-n in $val {
          // do the definition
          #{--$var-map-name}-#{$var}-#{$var-n}: $val-n;
        }
      } @else {
        // do the definition
        #{--$var-map-name}-#{$var}: $val;
      }
    }
  }
}
