/* MBEmpire */
@font-face {
	font-family: 'MBEmpire';
	src: url('MBEmpire-Regular.eot');
	src: url('MBEmpire-Regular.ttf') format('truetype'), url('MBEmpire-Regular.svg') format('svg');
	font-weight: normal;
}
@font-face {
	font-family: 'MBEmpire';
	src: url('MBEmpire-Medium.eot');
	src: url('MBEmpire-Medium.ttf') format('truetype'), url('MBEmpire-Medium.svg') format('svg');
	font-weight: 500;
}
@font-face {
	font-family: 'MBEmpire';
	src: url('MBEmpire-Light.eot');
	src: url('MBEmpire-Light.ttf') format('truetype'), url('MBEmpire-Light.svg') format('svg');
	font-weight: 300;
}
@font-face {
	font-family: 'MBEmpire';
	src: url('MBEmpire-Bold.eot');
	src: url('MBEmpire-Bold.ttf') format('truetype'), url('MBEmpire-Bold.svg') format('svg');
	font-weight: 700;
}
@font-face {
	font-family: 'MBEmpire';
	src: url('MBEmpire-Black.eot');
	src: url('MBEmpire-Black.ttf') format('truetype'), url('MBEmpire-Black.svg') format('svg');
	font-weight: 900;
}
/* AustinNewsHeadline */
@font-face {
	font-family: 'AustinNewsHeadline';
	src: url('AustinNewsHeadline-Roman.eot');
	src: url('AustinNewsHeadline-Roman.ttf') format('truetype'), url('AustinNewsHeadline-Roman.svg') format('svg');
	font-weight: normal;
}
@font-face {
	font-family: 'AustinNewsHeadline';
	src: url('AustinNewsHeadline-MediumItalic.eot');
	src: url('AustinNewsHeadline-MediumItalic.ttf') format('truetype'), url('AustinNewsHeadline-MediumItalic.svg') format('svg');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'AustinNewsHeadline';
	src: url('AustinNewsHeadline-Light.eot');
	src: url('AustinNewsHeadline-Light.ttf') format('truetype'), url('AustinNewsHeadline-Light.svg') format('svg');
	font-weight: 300;
}
@font-face {
	font-family: 'AustinNewsHeadline';
	src: url('AustinNewsHeadline-Bold.eot');
	src: url('AustinNewsHeadline-Bold.ttf') format('truetype'), url('AustinNewsHeadline-Bold.svg') format('svg');
	font-weight: 700;
}
