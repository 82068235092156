body {
  color: $text-color;
  letter-spacing: 0.03em;
  // padding-top: 90px;

  &.home,
  &.single-villa,
  &.page-id-12033,
  &.tax-tax_villa_regions,
  &.page-id-10230 {
    padding-top: 0;
  }

  .ui-datepicker-month {
    color:#000000;
    font-size: 12px !important;
  }

  .ui-datepicker-year{
    color:#000000;
    font-size:12px !important;
  }

  @media (min-width: $screen-md-min) {
      // padding-top: 70px;

      &.home,
      &.single-villa,
      &.page-id-12033,
      &.tax-tax_villa_regions,
      &.page-id-10230 {
        padding-top: 0px;
      }

  }
  @media (max-width: $screen-sm-max) {
    &.home {
      .main {
        .component-row:first-child {
          .row-wrapper{
            padding-top: 60px;
            box-sizing: content-box;
          }
        }
      }
    }
  }
}

img {
    max-width: 100%;
    height: auto;
}

.text-center {
  text-align: center;
}

.taggar {
  .vc-acf-field-picker-repeater-row {
    display: inline-block;
    .vc-acf-field-picker-repeater-column {
      margin: 7px;
      padding: 5px 10px;
      display: inline-block;
      border: 1px solid #707070;
      border-radius: 5px;
      background: $color-four;
      font-family: $headings-font-family;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      color: $white;
      cursor: pointer;
      transition: all 300ms ease;
      &:hover {
        background: $color-one;
      }
    }
  }
}


.no-matches {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.component-image-gallery {
  &:last-child {
    margin-bottom: 60px;
  }
  .section-gallery {
    display: flex;
    @media(max-width: 500px) {
      display: block;
    }
    .hotel-image {
      padding: 5px;
      outline: 0;
      width: 100%;
      display: block;
      -ms-flex: 1 1 auto;
      z-index: 0;
      @media(max-width: 500px) {
        display: block;
      }
      img {
        border-radius: 5px;
      }
    }
  }
}

.hotel-video {
  .container {
    max-width: 768px;
  }
  margin-top: 40px;
}

.hotel-image {
  .inner {
    position: relative;
    &:hover {
      .read-more {
        opacity: 1;
        span {
          &:after {
            width: 100%;
          }
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .read-more {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%);
    cursor: pointer;
    transition: opacity 300ms ease;
    border-radius: 5px;
    span {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-family: $headings-font-family;
      text-transform: uppercase;
      color: $white;
      font-size: 20px;
      font-weight: bold;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 2px;
        width: 0;
        background: $white;
        transition: width 300ms ease;
      }
      i {
        display: inline-block;
        margin-left: 7px;
        transition: transform 300ms ease;
      }
    }
  }
}

.hotels-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0px;
  .country-template-default & {
    padding-bottom: 60px;
  }
  .hotel-item {
    width: 100%;
    margin: 15px 0;
    @media(min-width: $screen-md-min) {
      width: 33.33334%;
    }
    &:hover {
      .read-more {
        opacity: 1;
        span {
          &:after {
            width: 100%;
          }
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
    .image-container {
      position: relative;
      img {
        border-radius: 5px;
      }
    }
    h5 {
      margin-top: 10px;
      margin-bottom: 0;
      &.location {
        margin-top: 20px;
        font-family: $font-family-sans-serif;
        font-weight: 500;
        font-style: italic;
        text-transform: none;
      }
    }
    .stars {
      margin-top: 10px;
      color: $color-three;
      font-size: 15px;
    }
    .offer {
      position: absolute;
      top: 20px;
      right: 0;
      left: 0;
      background: $color-one;
      color: $color-two;
      padding: 15px;
      text-align: center;
      span {
        font-family: $headings-font-family;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        display: block;
        & + span {
          font-family: $font-family-sans-serif;
          font-style: italic;
          font-weight: 100;
          text-transform: none;
          p {
            margin: 0;
          }
        }
      }
    }
    .read-more {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%);
      cursor: pointer;
      transition: opacity 300ms ease;
      border-radius: 5px;
      span {
        position: absolute;
        bottom: 15px;
        left: 15px;
        font-family: $headings-font-family;
        text-transform: uppercase;
        color: $white;
        font-size: 20px;
        font-weight: bold;
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          height: 2px;
          width: 0;
          background: $white;
          transition: width 300ms ease;
        }
        i {
          display: inline-block;
          margin-left: 7px;
          transition: transform 300ms ease;
        }
      }
    }
  }
}

.hotel-offers {
  margin-top: 60px;
  @media (min-width: 769px) {
    margin-bottom: 60px;
  }
  .hotel-offer {
    position: relative;
    padding: 40px;
    background: $color-one;
    text-align: center;
    & + .hotel-offer {
      margin-top: 30px;
    }
    &:after {
      display: none;
      content: $icon-plus-fat;
      position: absolute;
      bottom: -75px;
      left: 60px;
      font-family: 'icomoon';
      font-size: 150px;
      line-height: 1;
      color: $color-two;
      @media (min-width: 769px) {
        display: block;
        z-index: 1;
      }
    }
    .content {
      display: inline-block;
      max-width: 575px;
      color: $color-two;
      h1, h2, h3, h4, h5 {
        color: $color-two;
      }
      .component-button {
        margin-bottom: 0;
      }

      .form {
        display: none;
        max-width: 620px;
        margin: 0 auto;
        .gsection_title {
          display: none;
        }
        .gsection + .gfield {
          margin-top: 0;
        }
        @media (min-width: $screen-sm-min) {
          .gsection + .gfield:not(.fullwidth) + .gfield:not(.fullwidth) {
            margin-top: 0;
          }
        }
        .gfield {
          width: 100%;
          float: left;
          clear: initial !important;
          padding-left: 10px;
          padding-right: 10px;
          @media (min-width: $screen-sm-min) {
            width: 50%;
          }
          &.gsection {
            width: calc(100% - 20px);
            margin-left: 10px !important;
          }
          &.full-width {
            width: 100%;
          }
          .instruction {
            display: none;
          }
          .ginput_container_checkbox label {
            padding-right: 10px;
            margin: 0 auto;
            text-align: center;
          }
          .ginput_container {
            position: relative;
          }
        }
        .gform_footer {
          text-align: center;
        }
        label {
          display: block;
          font-family: $headings-font-family;
          font-size: 12px;
          font-weight: bold;
          color: $color-one;
          text-align: left;
          margin-bottom: 10px;
          text-transform: uppercase;
          color: $color-two;
        }
        input[type="text"], textarea, select {
          width: 100%;
          background: $white;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          font-family: $headings-font-family;
          font-size: 12px;
          font-weight: bold;
          color: $color-four;
          padding: 15px 12px;
          -webkit-appearance: none;
          -moz-appearance:    none;
          appearance:         none;
          outline: 0;
          line-height: 1.4;
          height: auto !important;
          @media (max-width: $screen-sm-max) {
            &:focus {
              font-size: 16px;
            }
          }
          &.error {
            background: $color-two;
            color: $color-one;
          }
        }
        input[type="submit"] {
          outline: 0;
          color: $color-one;
          background-color: $color-two;
        }
        .datepicker {
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-two;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $color-two;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
              color: $color-two;
          }
        }
        .btn {
          font-size: 12px;
          @media (min-width: 1380px) {
            font-size: 14px;
          }
          @media (min-width: 1501px) {
            font-size: 16px;
          }
        }
        .form-error-text {
          font-family: $headings-font-family;
          font-size: 14px;
          text-transform: uppercase;
          color: $color-five;
          @media (max-width: 1100px) {
            padding-bottom: 30px;
          }
        }
        .form-loading {
          padding-top: 10px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: $headings-font-family;
          text-transform: uppercase;
          color: $color-one;
          @media (max-width: 1100px) {
            padding-bottom: 30px;
          }
        }
        #gform_wrapper_5 {
          .gfield_select {
            padding: 11px 12px;
          }
          .validation_message {
            color: #000;
          }
          .validation_error {
            color: #fff;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
          }
        }
      }
    }
  }
}

.gform_confirmation_message_5 {
  border-top: 2px solid;
  padding-top: 10px;
}

.list-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
  @media (min-width: $screen-md-min) {
    height: 500px;
  }
  h1 {
    position: relative;
    z-index: 1;
    color: $white;
    margin: 0;
    max-width: 750px;
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(1,2,2,0.2) 1%,rgba(125,185,232,0) 60%);
  }
}

.single-hotel,
.single-villa{
  .banner {
    @extend .list-background;
    height: auto;
    padding: 150px 0;
    flex-direction: column;
    .stars {
      margin-top: 15px;
      z-index: 1;
      color: $white;
      font-size: 20px;
    }
    .location {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      font-style: italic;
      color: $white;
      margin-bottom: 10px;
      text-transform: none;
    }
    .component-button {
      margin-top: 40px;
      margin-bottom: 0;
      .btn {
        z-index: 1;
      }
    }
  }
  .hotel-content {
    padding-top: 60px;
    .hotel-intro {
      margin: 0 auto;
      max-width: 768px;
      h2 {
        word-break: break-word;
      }
    }
    & + .hotel-content {
      @media(max-width: 767px) {
        padding-top: 30px;
        .col-sm-6 + .col-sm-6 {
          padding-top: 30px;
        }
      }
    }
  }
  .hotel-gallery {
    margin-bottom: 60px;
    padding: 0 10px;
    .col-md-4 {
      padding: 0;
    }
    .hotel-image {
      padding: 5px;
      outline: 0;
      width: 100%;
      display: block;
      -ms-flex: 1 1 auto;
      z-index: 0;
      @media(max-width: 500px) {
        display: block;
      }
      img {
        border-radius: 5px;
      }
    }
  }
  .component-facts {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
    @media(max-width: 500px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .fact-item {
      display: inline-block;
      position: relative;
      width: 24%;
      padding-top: 24%;
      @media(max-width: 768px) {
        width: 49%;
        padding-top: 49%;
      }
      @media(max-width: 500px) {
        width: 75%;
        padding-top: 75%;
      }
      .inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        background: $color-one;
        border-radius: 50%;
        span {
          display: block;
          font-size: 100px;
          color: $color-two;
          line-height: 1;
          @media(max-width: 992px) {
            font-size: 9vw;
          }
          @media(max-width: 500px) {
            font-size: 23vw;
          }
          & + span {
            font-family: $headings-font-family;
            font-size: 18px;
            color: $color-four;
            font-weight: bold;
            text-transform: uppercase;
            max-width: 80%;
            line-height: 1.4;
            height: 50px; // font-size * 2 * 1.4 för att täcka två rader på alla.
            @media(max-width: 992px) {
              font-size: 1.5vw;
            }
            @media(max-width: 768px) {
              font-size: 3vw;
            }
            @media(max-width: 500px) {
              font-size: 5vw;
            }
          }
        }
      }
    }
  }
}

/* pickadate override */
.picker {
  &.picker--focused {
    .picker__day--highlighted, .picker__day--highlighted:hover {
      background: $color-two;
    }
    .picker__day--selected {
      background: $color-one;
      &:hover {
        background: $color-one;
      }
    }
  }
  &.picker--opened {
    .picker__holder {
      outline: 0;
      .picker__button--clear, .picker__button--close, .picker__button--today {
        &:before {
          display: none;
        }
      }
      .picker__button--clear:hover, .picker__button--close:hover, .picker__button--today:hover {
        background: $color-two;
        border-bottom-color: $color-two;
      }
    }
  }
  .picker__day {
    outline: 0;
    border: 0;
  }
  .picker__day--today:before {
    border-top: .5em solid $color-two;
  }
  .picker__day--infocus:hover, .picker__day--outfocus:hover {
    background: $color-four;
  }

  .picker__nav--next:hover, .picker__nav--prev:hover {
    background: none;
  }
}

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
  .container {
    width: auto;
  }
}

.villas-banner-heading{
  font-size: 56px;
  color: #fff;
  margin: 0;
  font-family: MBEmpire, sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.3;
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: -.04em;
  z-index: 1;
}

.color-black {
  color: $black;
}
.color-grey {
  color: $gray;
}
.color-grey-light {
  color: $gray-light;
}
.color-white {
  color: $white;
}
.color-one {
  color: $color-one;
}
.color-two {
  color: $color-two;
}
.color-three {
  color: $color-three;
}
.color-four {
  color: $color-four;
}
.color-five {
  color: $color-five;
}
.color-six {
  color: $color-six;
}
