/*
 * Background Colors
 */

@each $name, $color in $colors-map {
  .component-column > .bg-#{$name},
  .component-row.bg-#{$name} .row-wrapper {
    background-color: map-get($color, "background");
  }
}

/*
 * Background Image
 */

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.gradient-me {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(1,2,2,0.2) 1%,rgba(125,185,232,0) 60%);
      @media (min-width: $screen-md-min) {
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%,rgba(1,2,2,0.3) 1%,rgba(125,185,232,0) 60%);
      }
    }
  }
}

/*
 * Positioning
 */

@mixin rowFlex() {
  .row-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    > .container {
      align-self: stretch;
    }
    > .container > .row,
    > .row {
      display: flex;
      height: 100%;
      @media (max-width: $screen-xs-max) {
        flex-direction: column;
      }
    }
  }

  .vertical-stretch {
    align-self: stretch !important;
    .wpb_wrapper {
      display: flex;
      height: 100%;
    }
  }

  .vertical-align-top {
    align-items: flex-start;
    &.component-column {
      align-self: flex-start;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: flex-start;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: flex-start;
    }
  }

  .vertical-align-center {
    align-items: center;
    .container {
      align-self: center;
      @media (max-width: $screen-sm-max) {
        margin-top: -80px;
      }
    }
    &.component-column {
      align-self: center;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: center;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: center;
    }
  }

  .vertical-align-bottom {
    align-items: flex-end;
    &.component-column {
      align-self: flex-end;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: flex-end;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: flex-end;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  @include rowFlex;
}
.component-row.height-full {
  @include rowFlex;
}

.component-breadcrumbs {

  .breadcrumb-wrapper {
    overflow: hidden;
    background-color: $placeholder;
  }

  .breadcrumbs {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    overflow: hidden;

    li {
      float: left;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600px;
      line-height: 20px;
      color: $blackish;
      vertical-align: middle;

      .icon {
        margin-right: 10px;
        font-size: 18px;
        vertical-align: sub;
      }
    }

    li.separator {
      margin: 0;

      .icon {
        margin-right: 10px;
        font-size: 10px;
        color: $placeholder;
        vertical-align: baseline;
      }
    }

    li a {
      line-height: 20px;
      color: $blackish;

      &:hover {
        color: $placeholder;
      }
    }

    li.active {
      text-decoration: underline;
    }
  }
}

.component-button {
  @include clearfix;
  margin-bottom: $margin;

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.float-btn {
    float: left;
    margin: 0 5px $margin 5px;
  }
}
.wpb_wrapper .float-btn:nth-child(2) {

}

.component-accordion {
  &:nth-child(odd) {
    background: $color-five;
  }
  &:nth-child(even) {
    background: $color-two;
  }
  .accordion-wrapper {
    color: $black;
    .accordion-heading {
      display: flex;
      justify-content: space-between;
      padding: 15px 25px;
      cursor: pointer;
      span {
        font-size: 20px;
        vertical-align: middle;
        &:before {
          vertical-align: middle;
        }
        &.icon-plus {
          transition: transform 300ms ease;
        }
      }
    }
    .accordion-body {
      display: none;
      padding: 0 25px 15px 25px;
      font-size: 18px;
    }
    &.open {
      .accordion-heading {
        span.icon-plus {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.component-column {
  &.wpb_column {
    &.no-padding-top-bottom {
      .wpb_wrapper {
        padding: 0;
      }
    }
  }
}
.wpb_column {
  .wpb_wrapper {
    position: relative;
    .column-icon {
      display: none;
      position: absolute;
      @media (min-width: $screen-md-min) {
        display: block;
        bottom: -50px;
        left: calc(50% - 63px);
        &.column-icon-left {
          right: auto;
          left: 30px;
        }
        &.column-icon-right {
          left: auto;
          right: 30px;
        }
      }
      @media (min-width: $screen-lg-min) {
        bottom: -65px;
        left: calc(50% - 75px);
        &.column-icon-left {
          left: 40px;
        }
        &.column-icon-right {
          right: 40px;
        }
      }
      .icon {
        position: relative;
        &.icon-above {
          z-index: 5;
        }
        @media (min-width: $screen-md-min) {
          font-size: 100px;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 130px;
        }
        @each $name, $color in $colors-map {
          &.color-#{$name} {
            color: map-get($color, "background");
          }
        }
      }
    }
  }
}


.component-contact {

    .description {
        padding: 0;

        @media (min-width: $screen-xs-max) {
            padding: 0 0 0 15px;
        }


        .h1 {
            margin: 0;
            display: none;

            @media (min-width: $screen-xs-max) {
                display: block;
            }
        }

    }


    .details {
        .heading-left {
            text-align: center;
        }
        margin-bottom: $gap;
        text-align: center;
        padding-left: 0;

        .featured {
            max-width: 360px;
            margin-bottom: $margin;
            width: 100%;
            padding-left: 0;
        }

        @media (min-width: $screen-xs-max) {
            margin-bottom: 0;

            .featured {
                margin-bottom: 0;
                max-width: 100%;
            }
            .heading-left {
                margin: $margin/2 0;
                text-align: left;
            }
        }
    }

    .detail {
        display: block;
        font-size: 80%;
        margin-bottom: 3px;
        text-align: center;

        a {
            color: $black;
        }

        &.linkedin {
            margin-top: $margin;

            a {
                color: $brand-primary;
            }
        }

        @media (min-width: $screen-xs-max) {
            text-align: left;
        }
    }
}

.corporate-contact {
    padding-bottom: $gap;
    margin-bottom: $gap;
    position: relative;

    .h2 {
        margin-top: $margin/2;
    }

    &:before {
        background: $gray-light;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        height: 1px;
    }
}

.corporate-details {
    float: left;
    margin-right: $gap;

    span {
        display: inline-block;
        font-weight: 600;
    }
}
.component-contactlist {
  .list-wrapper {
    .contacts-wrapper {
      @media(min-width: $screen-sm-min) {
        display: flex;
        flex-flow: row wrap;
      }
    }
  }
  .component-heading {
    margin-bottom: $margin * 2;
    h2 {
      text-align: center;
      @media screen and (min-width: $screen-sm-min) {
        text-align: left;
      }
    }
  }
  .contacts-wrapper {
    margin-bottom: 50px;
  }

  .contact-wrapper {
    margin-bottom: $margin * 2;
  }
}

.component-contactsingle {
  .contact-image {
    width: 100%;
    max-width: 278px;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      max-width: 278px;
      max-height: 278px;
      border-radius: 50%;
      border: 2px $color-one solid;
      transition: all 300ms linear;
    }
  }

  .list-wrapper {
    @media(min-width: $screen-md-min) {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .contact-body {
    text-align: center;
    > a {
      display: block;
      padding-right: 0;
      margin-bottom: 16px;
      width: 100%;
      @media(min-width: $screen-tablet) and (max-width: $screen-md-max) {
        margin-bottom: 10px;
      }
      @media(max-width: 375px) {
        margin-bottom: 10px;
      }
      &:hover {
        h3 {
          text-decoration: underline;
        }
      }
      h3 {
        margin: 0;
        font-size: 32px;
        line-height: 41px;
        margin-bottom: 16px;
        @media(min-width: $screen-tablet) and (max-width: $screen-md-max) {
          font-size: 26px;
          line-height: 30px;
        }
        @media(max-width: 375px) {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }

  }

  .contact-text {
    text-align: center;



    .position,
    .details {
      padding: 0;
      list-style: none;
      color: $gray;
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
      margin-bottom: 0;
      @media(max-width: $screen-md-max) {
        font-size: 18px;
        line-height: 24px;
      }
      .desc {
        font-size: 18px;
        line-height: 24px;
        font-style: italic;
        @media(max-width: $screen-md-max) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .details {
      margin-bottom: 0;
    }

    .details a {
      font-weight: 300;
      color: $gray;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .vertical {
    text-align: left;
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .author-image {
      flex: 0 0 auto;
      margin-right: 15px;
      @media (max-width: 550px) {
        margin-bottom: 15px;
        margin-right: 0;
      }
      img {
        width: 85px;
        height: 85px;
        border: 3px solid $color-one;
        border-radius: 50%;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .name, .title, .email {
        display: block;
        margin: 1px 0;
      }
      .name {
        font-family: $headings-font-family;
        font-weight: bold;
        text-transform: uppercase;
        color: $black;
      }
      .email {
        text-decoration: underline;
        color: $gray;
        font-weight: 300;
      }
    }
  }
}


.single-contact {
  &--header {
    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
      padding-right: 15px;
      img {
        width: 100%;
        height: 100%;
        max-width: 180px;
        max-height: 180px;
        border-radius: 50%;
        border: 2px solid $color-one;
      }
    }
    &-meta {
      &-contact {

        span.meta {
          display: block;
          font-size: 18px;
          line-height: 26px;
          a {
            color: $gray;
            text-decoration: underline;
            font-weight: 300;
          }
        }
      }
    }
    @media(max-width: 480px) {
      display: block;
      text-align: center;
      &-image {
        margin-bottom: 30px;
        padding-right: 0;
      }
      &-meta {
        padding-left: 0;
      }
    }
  }

  &--content {
    text-align: center;
    & > .container > .row {
      height: auto;
    }
  }

  &--quote {
    background-image: url(pattern_beach_liggandes.svg);
    background-size: cover;
    background-position: center center;
    .container {
      text-align: center;
      blockquote {
        p {
          color: $black;
        }
      }
    }
  }
}
.component-contentbox {
  box-sizing: border-box;
  padding: $margin;
  position: relative;
  background-color: $white;
  p {
    margin: 0;
  }

  &.show-shadow {
    box-shadow: 0px 6px 14px -6px rgba(0,0,0,0.75);
  }

  @each $name, $color in $colors-map {
    &.color-#{$name} {
      color: map-get($color, "background");
    }
  }

  @each $name, $color in $colors-map {
    &.bg-#{$name} {
      background: map-get($color, "background");
    }
  }

  @media screen and (min-width: $screen-sm) {
    padding: $gap;
  }

  >div:last-child {
    margin-bottom: 0 !important;
  }

}

.component-divider {
  hr {
    border-top: 3px solid $text-color;
  }
  @each $name, $color in $colors-map {
    hr.color-#{$name} {
      border-color: map-get($color, "background");
    }
  }
}
.component-divider.big-gap hr {
  margin-top: $margin * 2.5;
  margin-bottom: $margin * 2.5;
}

.component-downloadablefiles {
  margin-bottom: $margin;
  width: 100%;

  @media (max-width: $screen-xs-max) {
    max-width: none;
    width: 100%;
  }

  .wrapper {
    padding: $margin;
  }

  .head {
    display: flex;
    align-items: center;

    .heading {
      display: block;
    }
  }

  .text {
    margin-bottom: $margin;
  }

  .files {
    ul {
      list-style: none;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    ul li {
      display: block;
    }

    a {
      line-height: 100%;
      display: inline-block;
      margin-bottom: $margin/2;

      @media (max-width: $screen-xs-max) {
        font-size: $font-size-small;
      }

      span.name {
        vertical-align: middle;
        text-decoration: underline;
        font-family: $headings-font-family;
        font-size: 18px;
        @media (min-width: $screen-md-min) {
          font-size: 22px;
        }
      }

      span.size {
        display: none;
        margin-bottom: -4px;
        margin-left: 5px;
        vertical-align: middle;
        font-family: $headings-font-family;
        font-size: 14px;
        @media (min-width: $screen-md-min) {
          display: inline-block;
        }
      }

      .icon {
        margin-left: 0;
        margin-right: 5px;
        font-size: 24px;
        width: 24px;
        position: relative;
        vertical-align: middle;
      }

      &:hover {
        color: $black;
      }
    }

    ul li:last-child a {
      margin-bottom: 0;
    }
  }

  &.color-white {
    .wrapper {
      background-color: $white;
    }
  }

  &.color-grey-light {
    .wrapper {
      background-color: $gray-lighter;
    }
  }

  &.color-brand {
    .wrapper { background-color: $brand-primary; }
    .head .heading { color: $white; }
    .text { color: $white; }
    .files a { color: $white; }
    .files a:hover { color: rgba($white, 0.8); }
  }
}

.component-continentlist,
.component-villaarchive,
.component-villacountrylist{
  display: flex;
  flex-wrap: wrap;
  .continent-item,
  .villa-item{
    float: none;
    width: 100%;
    @media(min-width: $screen-md-min) {
      width: 50%;
    }
    &:hover {
      .featured-image {
        &__background {
          transform: scale(1.1);
        }
        h5 {
          border-top: 3px solid $white;
          border-bottom: 3px solid $white;
          padding: 25px 0;
          font-size: 20px;
          @media(min-width: $screen-md-min) {
            padding: 35px 0;
            font-size: 26px;
          }
        }
      }
    }
    .featured-image {
      position: relative;
      padding-top: 56.25%;
      overflow: hidden;
      &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform 1.2s ease;
      }
      &__inner {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%);
      }
      h5 {
        width: 80%;
        margin: 0 10%;
        padding: 15px 0;
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
        letter-spacing: 1.3px;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #FFF;
        transition: border-top 600ms ease, border-bottom 600ms ease, font-size 900ms ease, padding 600ms ease;
        @media(min-width: $screen-md-min) {
          padding: 30px 0;
          font-size: 24px;
        }
      }
    }
    &__inner {
      padding: 25px 0 50px 0;
      .tag {
        display: inline-block;
        margin: 7px;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #707070;
        font-family: $headings-font-family;
        font-size: 12px;
        font-weight: 600;
        color: $color-one;
        text-transform: uppercase;
        transition: all 300ms linear;
        letter-spacing: 0.5px;
        &:hover {
          background: $color-one;
          color: $white;
        }
      }
    }
  }
}

.component-event {
  .meta {
    margin-bottom: 20px;

    .categories, .date {
      display: inline-block;
    }

    .date {
      margin-right: 10px;
    }
  }

  .content {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;

    .container {
      padding: 0;
      width: 100%;
    }

    .component-row {
      margin: 0;
    }
  }

  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-eventlist {
  .event-wrapper {
    border: 1px solid $gray-light;
    padding: 20px;
    margin-bottom: 20px;

    .date {
      display: inline-block;
      margin-bottom: 5px;
      color: $placeholder;
      font-size: 18px;
      line-height: 100%;
    }
    .heading,
    .description,
    .link {
      color: $gray;
      font-size: 16px;
    }
    .heading {
      margin-bottom: 2px;

      &:hover {
        color: $placeholder;
      }
    }
    .description {
      margin-bottom: 10px;
    }
    .link {
      font-weight: 300;

      &:hover {
        color: $placeholder;
      }

      .icon {
        margin: 0;
        color: $placeholder;
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }
  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-eventslider {
  .heading-wrapper {
    padding-left: 80px;
    padding-right: 80px;
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .component-heading {
      width: 49%;
      display: inline-block;
      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }

    .terms-wrapper {
      width: 49%;
      display: inline-block;
      text-align: right;
      float: right;

      @media (max-width: $screen-sm-max) {
        float: none;
        width: 100%;
        text-align: left;
      }
    }

    a {
      color: $gray;
      font-weight: 300;
      padding-left: 5px;
    }

    a.is-active {
      text-decoration: underline;
      color: $blackish;
    }

    a:hover,
    a:active,
    a:focus {
      color: $blackish;
    }
  }

  .events-wrapper {
    padding: 0 80px;

    @media (max-width: $screen-sm-max) {
      padding: 0 40px;
    }
  }

  .slick-track {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }

  .event-wrapper {
    width: 33%;
    padding: 30px;
    border-left: 1px solid $gray-light;
    height: 100%;
    flex: 1;

    &:focus {
      outline: none;
    }

    .date {
      display: inline-block;
      margin-bottom: 10px;
      color: $placeholder;
      font-size: 26px;
    }
    .heading,
    .description,
    .link {
      color: $gray;
      font-size: 16px;
    }
    .heading {
      margin-bottom: 5px;
      font-size: 20px;

      &:hover {
        color: $placeholder;
      }
    }
    .description {
      margin-bottom: 20px;
    }
    .link {
      font-weight: 300;

      &:hover {
        color: $placeholder;
      }

      .icon {
        margin: 0;
        color: $placeholder;
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }

  .slick-list {
    border-right: 1px solid $gray-light;
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 100;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 40px;
      content: $icon-chevron-left;
      color: $blackish;
      line-height: 40px;
    }

    &:hover:before {
      color: $placeholder;
    }

    &.slick-next {
      right: 0;

      &:before {
        content: $icon-chevron-right;
      }
    }

    &.slick-prev {
      left: 0;
    }

    @media (max-width: $screen-sm-max) {
      width: 20px;
      height: 20px;

      &:before {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}



.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper {
  .mdl-textfield {
  }

  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    color: $gray-dark;
  }

  .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: $black;
  }

  .mdl-textfield__label:after {
    background-color: $black;
    height: 2px;
    bottom: 15px;
  }

}

.gform_wrapper {
  position: relative;
}

.section-bg--white .gform_wrapper {
  background-color: $gray-lighter;
}

label.mdl-textfield__label, .gfield_label {
  color: $gray-dark;
  font-weight: 400;
  font-family: $headings-font-family;;
}

.file-input-wrapper,
.ginput_container_radio,
.ginput_container_list {
  margin-bottom: 10px;
}

.fileinput_label {
}

.ginput_container_fileupload {
  input {
  }
}

.ginput_container_select {
  select {
    width: 100% !important;
    display: block;
    height: 55px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 1.428571429;
    color: $white;
    background-color: $color-four;
    background-image: none;
    border: 2px solid $color-four;
    border-radius: $border-radius-base;
    outline: none;
  }
}

li.gfield_error {
  margin-bottom: 30px;
}

.gsection_description {
  margin-bottom: 25px;
}

.gfgeo-hidden-fields {
  display: none !important;
}

.gfgeo-map-wrapper {
  border: none !important;
}

.ginput_container.ginput_container_checkbox,
.ginput_container.ginput_container_radio {
  margin-bottom: $margin-bottom !important;
}

.gfield_checkbox {
  margin-bottom: $margin-bottom;
  li {
    margin-bottom: 0;
  }

  label, input[type=checkbox] {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
    font-weight: 100;
  }

  label {
    position: relative;
  }

  input[type=checkbox] + label:before {
    content: '';
    background: transparent;
    border: 1px solid $color-four;
    color: $color-four;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 5px;
    margin-bottom: 2px;
    text-align: center;
    line-height: 20px;
    font-size: 21px;
    transition: border 0.3s;
    border-radius: $border-radius-base;
  }

  input[type=checkbox]:checked + label:before {
    @include icomoon;
    line-height: 20px;
    content: $icon-checkmark;
    background: $color-four;
    color: $white;
    border-color: $color-four;
    font-size: 15px;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.gfield_radio {
  margin-bottom: $margin-bottom;

  li {
    margin-bottom: 0;
  }

  input[type=radio] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
  }

  label, input[type=radio] {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    font-weight: 100;
    margin-left: 0;
  }

  input[type=radio] + label:before {
    content: '';
    background: transparent;
    border: 1px solid $color-four;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 5px;
    margin-bottom: 2px;
    text-align: center;
    line-height: 25px;
    font-size: 24px;
    border-radius: 50%;
  }

  input[type=radio]:checked + label:before {
    background: $color-four;
    box-shadow: inset 0px 0px 0px 3px $white;
    border-color: $color-four;
  }
}

.ginput_container {
  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    //font-weight: 700;
  }
}

.gform_footer {
  input[type='submit'],
  button[type='submit'] {
    margin-bottom: 0;
    font-size: 16px !important;
  }
}

.gfield .mdl-textfield {
  width: 100%;
}

.gfield .mdl-textfield__input {
  font-weight: 400;
  font-family: $font-family-base;
  -webkit-appearance: none;
  border-radius: 0;
  color: $black;

}

.gfield .mdl-textfield__label {
  top: 30px;
}

.gfield .mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: $color-two;
}

.gfield .mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: $color-two;
}

.ginput_container_multiselect .gfield_select {
    color: $black;
}

.gfield_required {
  color: $color-two;
  font-size: 12px;
  margin-left: 5px;
}

// Inverted colors
.invert-colors .gform_wrapper {

  color: $white;

  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  label.mdl-textfield__label, .gfield_label{
    color: $white;
  }

  .mdl-textfield__label:after {
    background-color: $white;
  }

  .ginput_container_select {
    select {
      box-shadow: 0 0 0 2px $white;
    }
  }

  .mdl-textfield__input {
    border-bottom: 1px solid rgba($white,.5);
    color: $white;
  }



  .gfield_checkbox {
    input[type=checkbox] + label:before {
      border: 1px solid $white;
      color: $white;
    }

    input[type=checkbox]:checked + label:before {
      background: $white;
      color: $color-four;
      border-color: $white;
    }
  }


  .gfield_radio {
    input[type=radio] + label:before {
      border: 1px solid $white;
    }

    input[type=radio]:checked + label:before {
      background: $white;
      box-shadow: inset 0px 0px 0px 3px $color-four;
      border-color: $white;
    }
  }

  .gform_footer {
    input[type='submit'],
    button[type='submit'] {
      background-color: $white;
      color: $brand-primary;
      font-size: 16px;
      &:hover {
        background-color: rgba($white, 0.8);
      }
    }
  }

}

.component-gravityforms {
  .gform_wrapper {
    .field_sublabel_below {
      .ginput_container {
        label {
          display: none !important;
        }
      }
    }
    .ginput_complex {
      margin-top: 0 !important;
      input, select, textarea {
        margin-top: 15px !important;
      }
    }
    .gfield {
      width: 100%;
      float: left;
      clear: initial !important;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: $screen-sm-min) {
        width: 50%;
      }
      &.gsection {
        width: calc(100% - 20px);
        margin-left: 10px !important;
        padding: 0;
        padding-bottom: 10px;
      }
      &.full-width {
        width: 100%;
      }
      .instruction {
        display: none;
      }
      .ginput_container_checkbox label {
        padding-right: 10px;
        text-align: center;
        display: inline-block !important;
        &:before {
          margin-right: 10px !important;
        }
      }
      .ginput_container {
        position: relative;
      }
      label {
        display: block !important;
        font-family: $headings-font-family !important;
        font-size: 12px !important;
        font-weight: bold !important;
        color: $color-one !important;
        text-align: left !important;
        margin-bottom: 0 !important;
        text-transform: uppercase !important;
      }
      input[type="text"], textarea, select {
        width: 100% !important;
        background: $white !important;
        border: 1px solid #CCCCCC !important;
        border-radius: 5px !important;
        font-family: $headings-font-family !important;
        font-size: 12px !important;
        font-weight: bold !important;
        color: $color-two !important;
        padding: 15px 12px !important;
        -webkit-appearance: none !important;
        -moz-appearance:    none !important;
        appearance:         none !important;
        outline: 0 !important;
        line-height: 1.4 !important;
        height: auto !important;
        &::-webkit-input-placeholder {
          color: $color-two !important;
        }
        &::-moz-placeholder {
          color: $color-two !important;
        }
        &:-ms-input-placeholder {
          color: $color-two !important;
        }
        &:-moz-placeholder {
          color: $color-two !important;
        }
        &::-webkit-input-placeholder {
          color: $color-two !important;
        }
        @media (max-width: $screen-sm-max) {
          &:focus {
            font-size: 16px !important;
          }
        }
        &.error {
          background: $color-two !important;
          color: $color-one !important;
        }
      }
    }
  }
}
.component-footer {
  background-color: $color-one;
  padding: 80px 0;
  text-align: center;
  padding-bottom: 130px;
  @media (min-width: $screen-md-min) {
    padding-bottom: 160px;
  }
  .site-logo {
    display: inline-block;
    width: 250px;
    margin-bottom: 70px;
    svg {
      &:hover {
        path {
          fill: $color-two;
        }
      }
      path {
        transition: fill 300ms ease;
      }
    }
  }
  .social-icons {
    a {
      color: $white;
      margin: 0 20px;
      transition: color 300ms ease;
      &:hover {
        color: $color-two;
      }
      i {
        font-size: 24px;
      }
    }
  }
  .contact-info {
    font-family: $headings-font-family;
    font-size: 12px;
    margin-top: 20px;
    .info {
      display: inline-block;
      margin: 0 5px;
      b {
        color: $color-two;
      }
      a {
        color: $white;
      }
    }
  }
}

.component-header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: none;
  z-index: 6;
  background-color: $color-one;


  body.home &,
  body.single-villa &,
  body.page-id-12033 &,
  body.tax-tax_villa_regions &,
  body.page-id-10230 & {
    background-color: transparent;
  }

  &.show-nav {
    position: fixed;
  }

  .header-wrapper {
    background-color: $color-one;
    height: 70px;

    @media (min-width: $screen-md-min) {

      body.home & {
        height: 140px;
        padding: 5rem 0;
      }

      body.single-villa &,
      body.page-id-12033 &,
      body.tax-tax_villa_regions &,
      body.page-id-10230 & {
        height: 90px;
      }

    }

    .container {
      height: 100%;
      width: 100%;

      @media (min-width: $screen-lg-min) {
        width: $screen-lg-min;
      }

      .col-xs-12 {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
      }
    }
  }

  .header-navigation {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $screen-md-min) {
      display: flex;
    }

    nav {
      display: inline-flex;
      align-items: center;
      position: relative;
      line-height: 1;
      z-index: 3;

      .material-search-icon{
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        color: #fff;
        font-size: 24px;
        margin-left:40px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;

        a, a:visited, a:hover {
          color: $color-two;
        }

      }

      body.home &,
      body.single-villa &,
      body.page-id-12033 &,
      body.tax-tax_villa_regions &,
      body.page-id-10230 & {
        margin-bottom: -10px;
        .material-search-icon{
          a, a:visited, a:hover {
            color: $white;
          }
        }
      }

      >ul {
        >li {
          &.has-sub-menu {
            &:hover {
              >ul.sub-menu {
                display: block;
                animation: fade-in-slide .3s ease-out forwards;
              }

              >i {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          position: relative;
          display: inline-block;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
          padding: 25px 0;

          body.home &,
          body.single-villa &,
          body.page-id-12033 &,
          body.tax-tax_villa_regions &,
          body.page-id-10230 & {
            padding: 30px 0;
          }

          body.home &,
          body.single-villa &,
          body.page-id-12033 &,
          body.tax-tax_villa_regions &,
          body.page-id-10230 & {
            padding: 0;
          }

          &+li {
            margin-left: 40px;
          }

          &.has-sub-menu {
            position: relative;

            i {
              display: inline-block;
              margin-left: 8px;
              font-size: 10px;
              transition: transform 300ms ease;

              @media (min-width: $screen-lg-min) {
                font-size: 14px;
              }

              top: 27px;
              color: $color-four;

              body.home &,
              body.single-villa &,
              body.page-id-12033 &,
              body.tax-tax_villa_regions &,
              body.page-id-10230 & {
                color: $white;
              }

            }
          }

          &:hover {
            a {
              &:after {
                width: 100%;
              }
            }
          }

          a {
            position: relative;
            font-family: $headings-font-family;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;
            color: $color-two;

            body.home &,
            body.single-villa &,
            body.page-id-12033 &,
            body.tax-tax_villa_regions &,
            body.page-id-10230 & {
              color: $white;
            }

            letter-spacing: 0.8px;

            @media (min-width: $screen-lg-min) {
              font-size: 16px;
            }

            &:after {
              content: '';
              position: absolute;
              top: 100%;
              left: 0;
              width: 0;
              height: 2px;
              transition: width 300ms ease;
              background: $color-four;

              body.home &,
              body.single-villa &,
              body.page-id-12033 &,
              body.tax-tax_villa_regions &,
              body.page-id-10230 & {
                background: $white;
              }

            }
          }

          ul.sub-menu {
            display: none;
            position: absolute;
            padding: 5px 0px 15px 0px;
            top: 100%;
            left: -35px;
            border-bottom: 10px solid $color-three;
            background: $color-one;

            li {
              position: relative;
              display: block;
              margin: 0;
              padding: 7.5px 35px;
              white-space: nowrap;
              transition: all 300ms ease;
              box-sizing: border-box;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 34px;
                width: 0px;
                transition: all 300ms ease;
                background: $color-one;
              }

              &:hover,
              &.active {
                padding-right: 30px;
                padding-left: 40px;

                &:before {
                  width: 5px;
                  background: $color-two;
                }

                i {
                  transform: none;
                }

                ul.sub-menu {
                  animation: none;
                }

                //border-left: 5px solid $color-two;
              }

              img {
                border-radius: 10px;
                height: 50px;
                width: 50px;
                display: inline-block;
                margin-right: 14px;
                max-width: none;
              }

              &.has-img {
                &:before {
                  height: 100%;
                }

                a {
                  display: block;
                  height: 100%;
                }
              }

              a {
                font-weight: 500;
                color: $white;

                //display: block;
                &:after {
                  display: none;
                }

                i {
                  right: 0;
                }
              }

              &.has-sub-menu {
                i {
                  left: auto;
                  right: 35px;
                  top: 6px;

                  &.open {
                    transform: rotate(- 180deg);
                  }
                }

              }

              &>ul.sub-menu {
                position: static;
                border-bottom: none;
                display: none;
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                transition: all 300ms ease;
                opacity: 0;
                background: none;

                li {
                  &:before {
                    display: none;
                  }

                  span {
                    &:after {
                      content: ' ';
                      position: absolute;
                      bottom: -2px;
                      left: 0;
                      height: 2px;
                      width: 0;
                      background: $white;
                      transition: width 300ms ease;
                    }
                  }

                  &:hover,
                  &.active {
                    span {
                      &:after {
                        width: 100%;
                      }
                    }
                  }

                  a {
                    font-weight: 100;
                  }
                }

                li:first-child {
                  margin-top: 7.5px;
                }

                &.open {
                  display: block;
                  animation: fade-in-slide .3s ease-out forwards;
                }

                li {
                  padding-left: 15px;
                  padding-right: 0;
                }
              }

            }

          }
        }
      }

      .component-button {
        margin: 0 0 0 40px;

        .btn {
          font-size: 12px;
          @extend .btn-lighter-green;

          body.home &,
          body.single-villa &,
          body.page-id-12033 &,
          body.tax-tax_villa_regions &,
          body.page-id-10230 & {
            @extend .btn-green;
          }
        }
      }
    }
  }

  .site-logo__container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: $screen-md-min) {

      body.home &,
      body.single-villa &,
      body.page-id-12033 &,
      body.tax-tax_villa_regions &,
      body.page-id-10230 & {
        align-items: initial;
        height: auto;
      }
    }

    .site-logo {
      position: relative;
      z-index: 9999;
      padding: 0 70px;
      line-height: 1;
      box-sizing: content-box;

      body.home &,
      body.single-villa &,
      body.page-id-12033 &,
      body.tax-tax_villa_regions &,
      body.page-id-10230 & {
        height: 70px;
        width: 40px;
      }

      width: 45px;

      .logo-text {
        display: none;
      }

      @media (min-width: $screen-md-min) {

        body.home &,
        body.single-villa &,
        body.page-id-12033 &,
        body.tax-tax_villa_regions &,
        body.page-id-10230 & {
          padding: 0 120px;
          margin-top: -65px;

          a {
            svg {
              height: 44px;
            }
          }

          .logo-text {
            display: block;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 90px);
            width: 180px;
          }
        }
      }

      &.nav-open {
        a {
          svg {
            path {
              fill: $color-three !important;
            }
          }
        }
      }

      a {
        svg {
          height: 50px;
          width: auto;

          body.home &,
          body.single-villa &,
          body.page-id-12033 &,
          body.tax-tax_villa_regions &,
          body.page-id-10230 & {
            margin-top: 10px;
          }

          @media (min-width: $screen-md-min) {

            body.home &,
            body.single-villa &,
            body.page-id-12033 &,
            body.tax-tax_villa_regions &,
            body.page-id-10230 & {
              margin-top: 0;
              margin-bottom: -20px;
            }
          }

          path {
            transition: fill 300ms ease;
            fill: $color-four;

            body.home &,
            body.single-villa &,
            body.page-id-12033 &,
            body.tax-tax_villa_regions &,
            body.page-id-10230 & {
              fill: $white;
            }

          }
        }
      }
    }

    .site-logo__border {
      flex-grow: 1;

      @media (min-width: 1280px) {
        &.left {
          margin-left: -19px;
        }

        &.right {
          margin-right: -19px;
        }
      }

      @media (min-width: $screen-md-min) {

        body.home &,
        body.single-villa &,
        body.page-id-12033 &,
        body.tax-tax_villa_regions &,
        body.page-id-10230 & {
          border-bottom: 1px solid $white;
        }
      }
    }
  }

  .mobile-navigation {
    position: relative;
    z-index: 9999;

    #toggle-nav {
      display: inline-block;
      cursor: pointer;

      @media (min-width: $screen-md-min) {
        display: none;
      }

      .toggle-nav-lines {
        position: relative;
        display: inline-block;
        width: 40px;
        border-top: 4px solid $white;
        vertical-align: middle;
        transition: all 0.5s ease;

        &:after {
          transition: all 0.5s ease;
          content: "";
          position: absolute;
          top: -16px;
          left: 0px;
          width: 100%;
          border-top: 4px solid $white;
        }

        &:before {
          transition: all 0.5s ease;
          content: "";
          position: absolute;
          top: 8px;
          left: 0px;
          width: 100%;
          border-top: 4px solid $white;
        }
      }

      &.open {
        .toggle-nav-lines {
          border: 0;

          &:after {
            transform: rotate(45deg);
            top: -3px;
            border-color: $color-one;
          }

          &:before {
            transform: rotate(-45deg);
            top: -3px;
            border-color: $color-one;
          }
        }
      }
    }
  }
}

.component-heading {
  margin-bottom: $margin;

  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin-bottom: 0;
    position: relative;
    letter-spacing: -0.04em;

    @media (min-width: $screen-sm-min) {
      z-index: 1;
      &.margin-left {
        &.margin-small {
          margin-left: -80px;
        }
        &.margin-medium {
          margin-left: -120px;
        }
        &.margin-large {
          margin-left: -160px;
        }
      }
      &.margin-right {
        z-index: 1;
        &.margin-small {
          margin-right: -60px;
        }
        &.margin-medium {
          margin-right: -120px;
        }
        &.margin-large {
          margin-right: -180px;
        }
      }
    }

    @each $name, $color in $colors-map {
      &.color-#{$name} {
        color: map-get($color, "background");
      }
    }
  }

  a {
    overflow: hidden;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  .heading-sub {
    text-transform: uppercase;
  }

  .bs-padding {
    padding: 0 15px;
  }

  &.heading-left {
    text-align: left;
  }

  &.heading-center {
    text-align: center;
  }

  &.heading-right {
    text-align: right;
  }

  .line {
    content: "";
    display: inline-block;
    width: 100px;
    height: 5px;
    background-color: $brand-primary;
  }

}

.component-hotellist {

}

.component-icon {
  position: relative;
  text-align: center;
  margin-bottom: $margin;

  &.background {
    padding: 30px;
    @each $name, $color in $colors-map {
      &.bg-#{$name} {
        background: map-get($color, "background");
      }
    }
  }

  .icon {
    @each $name, $color in $colors-map {
      &.color-#{$name} {
        color: map-get($color, "background");
      }
    }
  }

  .heading {
    word-break: break-word;
    display: block;
    margin: 0;
    text-transform: uppercase;
    color: $black;
    -ms-flex: 1 1 auto
  }

  .link {
    display: inline-block;
    font-weight: $font-weight-base;

    &:hover, &:active, &:focus {
      color: inherit;
      outline: 0;
      .icon {
        transform: scale(1.1);
      }
    }
  }

  .icon {
    font-size: 36px;
    transition: color $transitionTime $easeCurve;
    display: block;
    text-align: center;
    margin-bottom: $gap/2;
    transform: scale(1);
    transition: transform $transitionTime $easeCurve;
    color: $black;
  }

  .text {
    display: block;
    margin: $gap/2 0 0;
    transition: color 0.2s;
    text-align: center;
    color: $text-color;
  }

  h5 {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
  }

  &.theme-top {
    .icon {
      font-size: 42px;
    }
  }

  &.theme-left,
  &.theme-mini,
  &.theme-mini-text {
    .top {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      margin: 0 $margin/2 0 0;
    }

    .heading, .text {
      //text-align: center;
    }
  }

  &.theme-left {
    text-align: left;
    .text {
      padding-left: 46px;
      text-align: left;
    }
  }

  &.theme-mini {
    .icon {
      font-size: 20px;
    }
    .heading {
      font-size: 16px;
      font-family: $headings-font-family;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      text-align: left;
    }
    .text {
      display: none;
    }
  }
  &.theme-mini-text {
    .icon {
      font-size: 20px;
    }
    .heading {
      font-size: 20px;
      font-weight: 300;
      text-transform: none;
      font-family: $font-family-sans-serif;
      color: $gray;
      text-align: left;
    }
    .text {
      display: none;
    }
  }
}

.component-iframe {
}

.component-image {
  max-width: 100%;
  margin-bottom: $margin;
  overflow: hidden;
  display: block;
  backface-visibility: hidden;

  img {
    margin: 0 auto;
    transform: scale(1);
    transition: transform $transitionTime $easeCurve;
  }

  p {
    color: $text-color;
    font-weight: $font-weight-base;
  }

  @media screen and (max-width: $screen-sm) {

    &.small {
      width: 100%;
    }

    .du-ratio-1x1,
    .du-ratio-4x3,
    .du-ratio-9x16 {
      //width: 50%;
      margin: 0 auto;
    }
  }
}

.component-image .img-text {
  padding-top: 10px;

  p {
    margin-bottom: 0;
    max-width: 475px;
  }
}

.component-image .missing-image {
  width: 100%;
}

.component-image.small {
  width: 50%;
}

.component-image.small.center {
  margin-left: auto;
  margin-right: auto;
}

.component-image.small.right {
  margin-left: auto;
}

.component-image.round {

  .coh-resp-img-ratio-wrapper {
    border-radius: 50%;
    overflow: hidden;
    backface-visibility: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .img-text * {
    text-align: center;
  }
}

a.component-image {
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.component-imageslider {
  overflow: hidden;
  margin-bottom: $margin;
  position: relative;

  &.a16x9 {
    padding-bottom: 56.25%;
  }

  &.a1x1 {
    padding-bottom: 100%;
  }

  &.a4x3 {
    padding-bottom: 75%;
  }

  &.a9x16 {
    padding-bottom: 177.77%;
  }

  &.anone .slider-wrapper {
    position: static;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
  }

  .slider-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .slick-slide:focus {
      outline: none;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 16px;
    text-align: center;
    z-index: 9;

    li {
      background-color: transparent;
      border: 2px solid $gray-light;
      width: 16px;
      height: 16px;
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        cursor: auto;
      }

      &.slick-active:before {
        border: 2px solid $placeholder;
        background-color: $placeholder;
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: 50%;
      }
    }
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 100;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 60px;
      content: $icon-chevron-left;
      line-height: 60px;
      color: rgba(255, 255, 255, 0.6);
    }

    &.slick-next {
      right: 30px;

      &:before {
        content: $icon-chevron-right;
      }
    }

    &.slick-prev {
      left: 30px;
    }

    @media (max-width: $screen-sm-max) {
      width: 30px;
      height: 30px;
      &:before {
        font-size: 30px;
        line-height: 30px;
      }
      &.slick-prev {
        left: 15px;
      }
      &.slick-next {
        right: 15px;
      }
    }
  }
}

.component-ingress {
  margin-bottom: $margin;

  @each $name, $color in $colors-map {
    p.color-#{$name} {
      color: map-get($color, "background");
    }
  }

  p {
    @include font-size($ingress-font-sizes);
    max-width: 810px;
    margin-bottom: 0;
  }

  .lead-left {
    text-align: left;
  }

  .lead-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .lead-right {
    text-align: right;
    margin-left: auto;
  }

}

$bar-height: 80px;
$bar-height-mobile: 50px;

#inspiration-trip-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $bar-height-mobile;
  color: $color-two;
  z-index: 9999;
  box-shadow: 0px -5px 24px -8px rgba(0,0,0,0.75);
  @media (min-width: $screen-md-min) {
    height: $bar-height;
  }
  &.inactive {
    display: none;
    .builder-active & {
      display: block;
    }
  }
  .bar {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    z-index: 9999;
    &__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-one;
    }
    &__logo {
      position: relative;
      z-index: 2;
      svg {
        height: 25px;
        width: 22px;
        margin-right: 10px;
        @media (min-width: $screen-md-min) {
          height: 35px;
          width: 32px;
        }
        path {
          fill: $color-five;
        }
      }
    }
    &__text {
      position: relative;
      z-index: 2;
      font-family: $headings-font-family;
      text-transform: uppercase;
      font-size: 16px;
      @media (min-width: $screen-md-min) {
        font-size: 26px;
      }
      span {
        text-transform: lowercase;
      }
    }
    &__button {
      display: none;
      position: relative;
      z-index: 2;
      margin-left: 25px;
      @media (min-width: $screen-md-min) {
        display: inline-block;
      }
      .component-button {
        margin-bottom: 0;
        font-size: 16px;
      }
    }
    &__trigger {
      position: absolute;
      top: -33px;
      left: calc(50% - 33px);
      width: 66px;
      height: 66px;
      background: $color-one;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0px -5px 24px -8px rgba(0,0,0,0.75);
      @media (min-width: $screen-md-min) {
        display: none;
      }
      i {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        color: $color-two;
      }
      .builder-active & {
        top: auto;
        bottom: -28px;
        transform: rotate(180deg);
      }
    }
    &__close {
      display: none;
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      align-items: center;
      font-family: $headings-font-family;
      font-size: 16px;
      text-transform: uppercase;
      color: $white;
      z-index: 2;
      cursor: pointer;
      @media (min-width: $screen-md-min) {
        display: flex;
      }
      i {
        font-size: 28px;
        margin-left: 7px;
      }
    }
  }
}
#inspiration-trip-builder-master {
  $iconSize: 80px;
  $choosableIconSize: 100px;
  $builderMaxSize: 900px;
  .builder {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    @media (max-width: 1100px) {
      flex-direction: column;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__mobile-navigation {
      display: block;
      justify-content: center;
      padding-top: 40px;
      transition: background 400ms ease;
      text-align: center;
      padding-bottom: 10px;
      @media (min-width: 1101px) {
        display: none;
      }
      &.show-sidebar {
        background: $color-two;
      }
      .tab {
        display: inline-block;
        width: 140px;
        padding: 12px 0;
        text-align: center;
        border: 2px solid $color-one;
        border-radius: 5px;
        font-family: $headings-font-family;
        font-size: 12px;
        text-transform: uppercase;
        color: $color-one;
        margin-right: 5px;
        & + .tab {
          margin-left: 5px;
          margin-right: 0;
        }
        &.active {
          background: $color-one;
          color: $color-two;
        }
        i {
          margin-right: 5px;
        }
      }
    }
    &__side {
      display: none;
      flex-direction: column;
      position: relative;
      flex: 0 0 auto;
          flex: auto;
      width: 100%;
      background: $color-two;
      padding: 10px 20px 0 20px;
      @media (min-width: 1101px) {
        display: flex !important;
        width: 30%;
        max-width: 620px;
        height: 100%;
      }
      @media (max-width: 1100px) {
        flex: auto;
        &.mobile-show {
          display: block;
          animation: fade-in-flex 400ms ease forwards;
        }
        &.mobile-hide {
          animation: fade-out 400ms ease forwards;
        }
      }
      h5 {
        padding-top: 25px;
        font-size: 22px;
        line-height: 1.4;
        span {
          text-transform: lowercase;
        }
      }
      .choices {
        flex: 1 1 auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
        .choice {
          border-bottom: 1px solid #E6C0B6;
          .inner {
            display: flex;
            padding: 10px 0;
            .icon-container {
              flex: 0 0 auto;
              font-size: 36px;
              color: $color-four;
              line-height: 1;
            }
            .content {
              display: flex;
              justify-content: center;
              flex-direction: column;
              flex: 1 1 auto;
              padding: 0 20px;
              color: $color-one;
              line-height: 1;
              text-transform: uppercase;
              .category {
                display: block;
                font-family: $headings-font-family;
                font-size: 10px;
                margin-bottom: 3px;
              }
              .title {
                display: block;
                font-family: $headings-font-family;
                font-size: 14px;
                font-weight: bold;
              }
            }
            .remove {
              cursor: pointer;
              .icon {
                flex: 0 0 auto;
                font-size: 28px;
                color: $color-one;
                line-height: 1;
              }
            }
          }
        }
      }
      .side__text {
        flex: 1 1 auto;
      }
      .side__bottom {
        flex: 0 0 auto;
        width: 100%;
        padding-top: 40px;
        .bottom__social {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 25px;
          opacity: 0.4;
          transition: opacity 400ms ease, padding-bottom 400ms ease;
          @media (min-width: 1101px) {
            margin-bottom: 30px;
            border-bottom: 1px solid #E6C0B6;
          }
          &.active {
            opacity: 1;
          }
          .share-buttons {
            width: 100%;
            text-align: center;
          }
          .text {
            display: inline-block;
            font-family: $headings-font-family;
            font-size: 12px;
            text-transform: uppercase;
            line-height: 1;
            color: $black;
            padding-right: 5px;
          }
          .icons {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            .icon {
              color: $color-five;
              font-size: 18px;
              margin: 0 3px;
              cursor: pointer;
            }
          }
          .url-box-loading {
            padding-top: 10px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-family: $headings-font-family;
            text-transform: uppercase;
            color: $black;
          }
          .url-box {
            padding-top: 10px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-family: $headings-font-family;
            font-weight: 500;
            color: $black;
            .inner {
              display: inline-block;
              background: darken($color-two, 5%);
              padding: 3px 15px;
              border-radius: 5px;
            }
          }
        }
        .bottom__buttons {
          display: block;
          @media (min-width: 1301px) {
            display: flex;
          }
          &:not(.active) {
            .btn-bg {
              display: none;
            }
          }
          &.active {
            .component-button {
              .btn {
                background: $color-one;
              }
            }
          }
          .component-button {
            flex: 1 1 auto;
            margin-bottom: 10px;
            & + .component-button {
              margin-bottom: 20px;
            }
            @media (min-width: 1301px) {
              padding: 0 10px 0 0;
              margin-bottom: 20px;
              & + .component-button {
                padding: 0 0 0 10px;
              }
            }
            .btn {
              width: 100%;
              background: #98928C;
              font-size: 12px;
              @media (min-width: 1380px) {
                font-size: 14px;
              }
              @media (min-width: 1501px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    &__main {
      flex: 1 1 auto;
      padding-bottom: 40px;
      width: 100%;
      @media (min-width: 1101px) {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @media (max-width: 1100px) {
        &.mobile-show {
          display: block;
          animation: fade-in 400ms ease forwards;
        }
        &.mobile-hide {
          animation: fade-out 400ms ease forwards;
        }
      }
      @media (min-width: 1101px) {
        display: block !important;
        width: 70%;
      }
      &.loading {
        height: 100%;
      }
      .main__content, .main__submit {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 40px 20px 0 20px;
        .content__title-container {
          position: relative;
          width: 100%;
          max-width: 900px;
          margin: 0 auto 0px auto;
          text-align: center;
          @media (min-width: 1401px) {
            margin: 0 auto 0 auto;
          }
          .content__go-back {
            display: none;
            position: absolute;
            top: 9px;
            left: 0;
            font-family: $headings-font-family;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            color: $color-one;
            cursor: pointer;
            @media (min-width: 1101px) {
              display: block;
            }
            i {
              margin-right: 5px;
            }
          }
          h4 {
            color: $color-one;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .main__submit {
        p {
          font-size: 24px;
          font-style: italic;
          color: $color-one;
          padding-top: 15px;
        }
        .form {
          max-width: 620px;
          margin: 0 auto;
          .gsection_title {
            display: none;
          }
          .gsection + .gfield {
            margin-top: 0;
          }
          @media (min-width: $screen-sm-min) {
            .gsection + .gfield:not(.fullwidth) + .gfield:not(.fullwidth) {
              margin-top: 0;
            }
          }
          .gfield {
            width: 100%;
            float: left;
            clear: initial !important;
            padding-left: 10px;
            padding-right: 10px;
            @media (min-width: $screen-sm-min) {
              width: 50%;
            }
            &.gsection {
              width: calc(100% - 20px);
              margin-left: 10px !important;
            }
            &.full-width {
              width: 100%;
            }
            .instruction {
              display: none;
            }
            .ginput_container_checkbox label {
              padding-right: 10px;
              margin: 0 auto;
              text-align: center;
            }
            .ginput_container {
              position: relative;
            }
          }
          .gform_footer {
            text-align: center;
          }
          label {
            display: block;
            font-family: $headings-font-family;
            font-size: 12px;
            font-weight: bold;
            color: $color-one;
            text-align: left;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
          input[type="text"], textarea, select {
            width: 100%;
            background: $white;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            font-family: $headings-font-family;
            font-size: 12px;
            font-weight: bold;
            color: $color-four;
            padding: 15px 12px;
            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;
            outline: 0;
            line-height: 1.4;
            height: auto !important;
            @media (max-width: $screen-sm-max) {
              &:focus {
                font-size: 16px;
              }
            }
            &.error {
              background: $color-two;
              color: $color-one;
            }
          }
          input[type="submit"] {
            outline: 0;
          }
          .datepicker {
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: $color-two;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $color-two;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $color-two;
            }
          }
          .btn {
            font-size: 12px;
            @media (min-width: 1380px) {
              font-size: 14px;
            }
            @media (min-width: 1501px) {
              font-size: 16px;
            }
          }
          .form-error-text {
            font-family: $headings-font-family;
            font-size: 14px;
            text-transform: uppercase;
            color: $color-five;
            @media (max-width: 1100px) {
              padding-bottom: 30px;
            }
          }
          .form-loading {
            padding-top: 10px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-family: $headings-font-family;
            text-transform: uppercase;
            color: $color-one;
            @media (max-width: 1100px) {
              padding-bottom: 30px;
            }
          }
        }
      }
      .main__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1 1 auto;
        h4 {
          margin-bottom: 30px;
          color: $color-one;
        }
      }
      .main__complete {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1 1 auto;
        text-align: center;
        padding: 0 15px;
        h4 {
          margin-bottom: 30px;
          color: $color-one;
        }
        .buttons {
          margin-top: 20px;
          .component-button {
            display: inline-block;
            margin: 5px;
          }
        }
      }
      .choices {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 300px;
        @media (min-width: 640px) {
          max-width: 600px;
        }
        @media (min-width: 940px) and (max-width: 1100px) {
          max-width: 900px;
        }
        @media (min-width: 1381px) {
          max-width: 900px;
        }
        .choice {
          .choice-notification {
            z-index: 1;
            position: absolute;
            top: -10px;
            right: -10px;
            background: $color-five;
            color: $white;
            font-size: 13px;
            font-weight: bold;
            font-family: $headings-font-family;
            height: 20px;
            width: 20px;
            padding-left: 1px;
            line-height: 20px;
            border-radius: 50%;
            text-align: center;
          }
          &:not(.choosable) {
            width: 50%;
            margin: 20px 0;
            cursor: pointer;
            @media (min-width: 501px) {
              width: 33.33333334%;
            }
            @media (min-width: 769px) {
              width: 25%;
            }
            .category {
              text-align: center;
              .icon {
                position: relative;
                display: block;
                margin: 0 auto;
                height: $iconSize;
                width: $iconSize;
                line-height: $iconSize;
                border: 1px solid #129189;
                border-radius: 10px;
                background: $color-four;
                color: $white;
                font-size: 35px;

              }
              .image {
                position: relative;
                display: block;
                margin: 0 auto;
                height: $iconSize;
                width: $iconSize;
                border-radius: 10px;
              }
              span {
                display: block;
                font-family: $headings-font-family;
                font-size: 12px;
                text-transform: uppercase;
                margin-top: 8px;
                font-weight: bold;
                color: #0E3535;
              }
            }
          }
          &.choosable {
            width: 100%;
            margin: 10px 0;
            @media (min-width: 640px) {
              width: 50%;
            }
            @media (min-width: 940px) and (max-width: 1100px) {
              width: 33.33333334%;
            }
            @media (min-width: 1381px) {
              width: 33.33333334%;
            }
            .inner {
              display: flex;
              padding: 0 7.5px;
              .image {
                position: relative;
                flex: 0 0 auto;
                display: block;
                height: $choosableIconSize;
                width: $choosableIconSize;
                border-radius: 5px 0 0 5px;
                &.parent {
                  cursor: pointer;
                }
              }
              .icon {
                position: relative;
                flex: 0 0 auto;
                display: block;
                height: $choosableIconSize;
                width: $choosableIconSize;
                line-height: $choosableIconSize;
                border-radius: 5px 0 0 5px;
                background: $color-four;
                color: $white;
                font-size: 46px;
                text-align: center;
              }
              .content {
                position: relative;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                background: $white;
                padding: 15px 15px 7.5px 15px;
                border-radius: 0 5px 5px 0;
                line-height: 1;
                border-top: 1px solid #CCC;
                border-right: 1px solid #CCC;
                border-bottom: 1px solid #CCC;
                .title {
                  display: block;
                  flex: 1 0 auto;
                  font-family: $headings-font-family;
                  font-size: 11px;
                  line-height: 1.2;
                  color: $color-one;
                  text-transform: uppercase;
                  font-weight: bold;
                  word-break: break-word;
                  &.parent {
                    cursor: pointer;
                  }
                }
                .title-mini {
                  display: block;
                  flex: 1 0 auto;
                  font-family: $headings-font-family;
                  font-size: 8px;
                  line-height: 1.2;
                  color: $color-one;
                  text-transform: uppercase;
                  font-weight: bold;
                  word-break: break-word;
                  &.parent {
                    cursor: pointer;
                  }
                }
                .slider {
                  display: flex;
                  flex: 0 0 auto;
                  align-items: center;
                  padding-top: 5px;
                  border-top: 1px solid rgba(14, 53, 53, 0.2);
                  cursor: pointer;
                  .text {
                    flex: 1 1 auto;
                    padding-right: 8px;
                    font-family: $headings-font-family;
                    font-size: 8px;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: right;
                    line-height: 1.4;
                    color: $color-one;
                  }
                  .element {
                    position: relative;
                    flex: 0 0 auto;
                    width: 40px;
                    height: 20px;
                    border-radius: 25px;
                    border: 1px solid $color-one;
                    transition: background 300ms ease;
                    cursor: pointer;
                    .thumb {
                      position: absolute;
                      box-sizing: content-box;
                      top: -2px;
                      left: -2px;
                      height: 20px;
                      width: 20px;
                      line-height: 20px;
                      border: 1px solid $color-one;
                      border-radius: 50%;
                      background: $white;
                      transition: left 100ms ease;
                      color: $color-one;
                      text-align: center;
                      font-size: 14px;
                      &:after {
                        font-family: 'icomoon';
                        content: $icon-delete;
                      }
                    }
                    &.chosen {
                      background: $color-one;
                      .thumb {
                        left: calc(100% - 20px);
                        transform: rotate(0deg);
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        color: $color-one;
                        &:after {
                          content: $icon-checkmark;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.fade-enter-active {
  transition: opacity .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-leave-active {
  transition: opacity .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}

.main__loading {
  .spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 0 auto;
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-two;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
}

.bottom__social, .main__submit .form .form-loading {
  .spinner {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
  }

  .spinner > div {
    width: 8px;
    height: 8px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

#inspiration-trip-post-single-tags {
  padding-bottom: 75px;
  .row {
    display: flex;
    flex-wrap: wrap;
    .trip-category {
      position: relative;
      width: 100%;
      display: inline-block;
      margin: 45px 0;
      & + .trip-category {
        &:before {
          font-family: 'icomoon';
          content: $icon-plus-fat;
          display: block;
          position: absolute;
          top: -65px;
          left: calc(50% - 20px);
          line-height: 40px;
          height: 40px;
          width: 40px;
          font-size: 40px;
          color: $color-two;
        }
      }
      @media (min-width: 700px) {
        width: 50%;
        margin: 20px 0;
        & + .trip-category:before {
          top: calc(50% - 20px);
          left: -20px;
        }
        &:nth-child(2n + 1) {
          &:before {
            display: none;
          }
        }
      }
      @media (min-width: $screen-lg-min) {
        width: 33.333334%;
        &:nth-child(2n + 1) {
          &:before {
            display: block;
          }
        }
        &:nth-child(3n + 1) {
          &:before {
            display: none;
          }
        }
      }
      .inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 280px;
        margin: 0 auto;
        .image {
          flex: 0 0 auto;
          height: 100px;
          border-radius: 5px 5px 0 0;
        }
        .icon {
          width: 100%;
          display: block;
          z-index: 0;
          flex: 0 0 auto;
          height: 100px;
          line-height: 100px;
          text-align: center;
          font-size: 50px;
          border-radius: 5px 5px 0 0;
          background: $color-four;
          color: $white;
        }
        .content {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          padding: 15px;
          border: 1px solid #CCC;
          border-top: 0;
          border-radius: 0 0 5px 5px;
          font-family: $headings-font-family;
          text-transform: uppercase;
          color: $color-one;
          .title-mini {
            flex: 0 0 auto;
            display: block;
            font-size: 14px;
          }
          .title {
            flex: 1 1 auto;
            display: block;
            margin-top: 8px;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 15px;
          }
          .slider {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            padding-top: 10px;
            border-top: 1px solid rgba(14, 53, 53, 0.2);
            cursor: pointer;
            .text {
              flex: 1 1 auto;
              padding-right: 8px;
              font-family: $headings-font-family;
              font-size: 8px;
              font-weight: 500;
              text-transform: uppercase;
              text-align: right;
              line-height: 1.4;
              color: $color-one;
            }
            .element {
              position: relative;
              flex: 0 0 auto;
              width: 40px;
              height: 20px;
              border-radius: 25px;
              border: 1px solid $color-one;
              transition: background 300ms ease;
              cursor: pointer;
              .thumb {
                position: absolute;
                box-sizing: content-box;
                top: -2px;
                left: -2px;
                height: 20px;
                width: 20px;
                line-height: 20px;
                border: 1px solid $color-one;
                border-radius: 50%;
                background: $white;
                transition: left 100ms ease;
                color: $color-one;
                text-align: center;
                font-size: 14px;
                &:after {
                  font-family: 'icomoon';
                  content: $icon-delete;
                }
              }
              &.chosen {
                background: $color-one;
                .thumb {
                  left: calc(100% - 20px);
                  transform: rotate(0deg);
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                  color: $color-one;
                  &:after {
                    content: $icon-checkmark;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#inspiration-trip-builder-master {
  z-index: 9998;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $bar-height-mobile;
  transition: height 300ms ease, padding-top 300ms ease;
  @media (min-width: $screen-md-min) {
    padding-top: $bar-height;
  }
  &.active {
    height: 100%;
    background: rgba(255, 255, 255, 1);
    @media (min-width: $screen-md-min) {
      background: rgba(255, 255, 255, 0.95);
    }
  }
}
body.builder-active {
  height: 100%;
  overflow: hidden;
  touch-action: none;
}
.component-inspirationtriplandingpage {
  width: 100vw;
}
.component-inspirationtripslider {
  display: none;
  text-align: center;
  word-break: break-word;
  .inspiration-trip-slider {
    position: relative;
    .inspiration-trip-slide {
      padding: 15px 0;
      @media (min-width: $screen-md-min) {
        padding: 15px 40px;
      }
      h1 {
        margin-bottom: 22px;
      }
      .tags {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        margin-bottom: 25px;
        .tag {
          display: flex;
          align-items: center;
          font-size: 20px;
          color: $color-one;
          font-style: italic;
          letter-spacing: 1px;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          @media (min-width: $screen-md-min) {
            font-size: 34px;
          }
          & + .tag {
            &:before {
              position: relative;
              display: inline-block;
              content: $icon-plus-fat;
              font-family: 'icomoon';
              font-size: 8px;
              color: $color-two;
              margin: 0 10px;
              @media (min-width: $screen-md-min) {
                font-size: 11px;
              }
            }
          }
        }
      }
      .component-button {
        position: relative;
        z-index: 99999;
      }
      .price-info {
        font-family: $headings-font-family;
        font-size: 10px;
        @media (min-width: $screen-md-min) {
          font-size: 16px;
        }
      }
    }
    &.row-background {
      .inspiration-trip-slide {
        h1 {
          color: $white;
        }
        .tags .tag {
          color: $white;
        }
        .price-info {
          color: $white;
        }
      }
    }
  }
  ul.tin-slide-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    @media (min-width: $screen-md-min) {
      display: none;
    }
    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: transparent;
      border: 2px solid $color-two;
      border-radius: 100%;
      margin: 0 5px;
      transition: background 600ms ease;
      box-sizing: content-box;
      &.on {
        background: $color-two;
      }
    }
  }
  nav.tin-slide-next-prev {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }
    &.row-background {
      .tin-slide-next, .tin-slide-prev {
        &:before {
          color: $white;
        }
      }
    }
    .tin-slide-next, .tin-slide-prev {
      z-index: 5;
      position: absolute;
      top: calc(50% - 20px);
      height: 40px;
      width: 40px;
      line-height: 1;
      &:hover {
        &:before {
          opacity: 1;
          transform: scale(1.05);
        }
      }
      &:before {
        display: inline-block;
        width: 50px;
        height: 50px;
        font-family: 'icomoon';
        font-size: 40px;
        color: $color-two;
        opacity: 0.6;
        transition: all 300ms ease;
      }
    }
    .tin-slide-next {
      right: 0;
      &:before {
        content: $icon-arrow-right-circle;
      }
    }
    .tin-slide-prev {
      left: 0;
      &:before {
        content: $icon-arrow-left-circle;
      }
    }
  }
}

.component-inspirationtripteaserlist {
  .teaser-row {
    display: flex;
    flex-wrap: wrap;
  }
  .inspirationtrip-teaser {
    padding: 10px;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: 50%;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n + 2) {
        padding-right: 0;
      }
    }
    a {
      text-align: center;
      cursor: pointer;
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;
      border-radius: 5px;
      height: 100%;
    }
    .tags {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      margin-bottom: 25px;
      .tag {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: $white;
        font-style: italic;
        letter-spacing: 1px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        @media (min-width: $screen-md-min) {
          font-size: 24px;
        }
        & + .tag {
          &:before {
            position: relative;
            display: inline-block;
            content: $icon-plus-fat;
            font-family: 'icomoon';
            font-size: 8px;
            color: $color-two;
            margin: 0 10px;
            @media (min-width: $screen-md-min) {
              font-size: 11px;
            }
          }
        }
      }
    }
    .image {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transform: scale(1);
      transition: transform 1.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

background: radial-gradient(ellipse at center, rgba(0,0,0,0.8) 0%,rgba(1,2,2,0.8) 1%,rgba(125,185,232,0) 60%);
        opacity: 0.3;
      }
    }
    .pattern {

    }

    .wrapper {
      padding: 30px;
      min-height: 250px;
      text-align: center;
      position: relative;
      justify-content: center;
      height: 100%;
      display: flex;
      @media (min-width: $screen-md-min) {
        padding: 70px 30px;
        min-height: 360px;
      }
      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .read-more {
        position: absolute;
        bottom: 20px;
        left: 30px;
        right: 30px;
        display: none;
        color: $white;
        font-family: $headings-font-family;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }
        @media (min-width: $screen-md-min) {
          font-size: 20px;
        }
        span {
          position: relative;
          opacity: 0;
          transition: opacity 300ms ease;
          &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 0;
            background: $white;
            transition: width 300ms ease;
          }
          i {
            display: inline-block;
            margin-left: 7px;
            transition: transform 300ms ease;
          }
        }
      }
    }
    .heading {
      margin-bottom: 10px;
      color: $white;
      line-height: 1.3;
      text-transform: none;
      word-break: break-word;
      font-weight: 700;
      text-transform: uppercase;
    }
    .price-info {
      font-family: $headings-font-family;
      font-size: 10px;
      color: $white;
      @media (min-width: $screen-md-min) {
        font-size: 16px;
      }
    }

    /* Hover effects */
    &:hover {
      .read-more {
        span {
          opacity: 1;
          &:after {
            width: 100%;
          }
          i {
            transform: rotate(180deg)
          }
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    .column-vertical-stretch .component-teaserbackgroundimage {
      height: 100%;

      .image-wide, .image-square {
        height: 100%;
        padding-top: 0;

        .wrapper {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

.single-inspiration_trip {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 40px 15px;
    @media (min-width: $screen-md-min) {
      height: 500px;
    }
    h1 {
      position: relative;
      z-index: 1;
      color: $white;
      margin: 0;
      max-width: 750px;
      text-align: center;
    }
    .overlay {
      position: absolute;
      top: 0;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(1,2,2,0.2) 1%,rgba(125,185,232,0) 60%);
    }
    .tags {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      margin-bottom: 25px;
      .tag {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: $white;
        font-style: italic;
        letter-spacing: 1px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        @media (min-width: $screen-md-min) {
          font-size: 34px;
        }
        & + .tag {
          &:before {
            position: relative;
            display: inline-block;
            content: $icon-plus-fat;
            font-family: 'icomoon';
            font-size: 8px;
            color: $color-two;
            margin: 0 10px;
            @media (min-width: $screen-md-min) {
              font-size: 11px;
            }
          }
        }
      }
    }
    .component-button {
      position: relative;
      z-index: 1;
      margin-top: 25px;
    }
    .price-info {
      font-family: $headings-font-family;
      font-size: 10px;
      color: $white;
      @media (min-width: $screen-md-min) {
        font-size: 16px;
      }
    }
  }
  .trip-intro {
    margin: 0 auto;
  }
}
.component-logoslider {

  a {
    display: block;

    &:focus {
      outline: none;
    }
  }

  .slick-slide {
    padding: 0 1em;

    &:focus {
      outline: none;
    }
  }

  .item-bg-image {
    height: 170px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:focus {
      outline: none;
    }
  }

  .logo-wrapper {
    margin-bottom: 70px;

  }

  .slick-dots {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 15px;
    text-align: center;

    li {
      border: 3px solid #bfbfbf;
      background-color: #bfbfbf;
      width: 17px;
      height: 17px;
      list-style: none;
      display: inline-block;
      margin-right: 5px;
      border-radius: 50%;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        background-color: transparent;
        border-color: $brand-primary;
        cursor: auto;
      }
    }
  }
}

.component-mobilemenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  padding-top: 175px;
  padding-bottom: 100px;
  z-index: 9998;
  display: none;
  transition: all 300ms ease;
  overflow: scroll;
  body.admin-bar & {
    top: 46px;
    @media(min-width: 783px) {
      top: 32px;
    }
  }
  &:before {
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    height: 135px;
    width: 100%;
    background: $white;
    z-index: 3;
    box-shadow: 0px 18px 70px 8px rgba(255,255,255,1);
    body.admin-bar & {
      top: 46px;
      @media(min-width: 783px) {
        top: 32px;
      }
    }
  }
  &:after {
    content: ' ';
    position: fixed;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    z-index: 3;
    box-shadow: 0px -22px 70px 8px rgba(255,255,255,1);
  }
  .logo-container {
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 4;
    opacity: 0;
    display: none;
    body.admin-bar & {
      top: 136px;
      @media(min-width: 783px) {
        top: 122px;
      }
    }
    svg {
      width: 60%;
      max-width: 275px;
      height: auto;
      path {
        fill: $color-three;
      }
    }
  }
  &.hide-nav {
    display: block;
    animation: fade-out .4s ease-out forwards;
  }
  &.show-nav {
    animation: fade-in .3s ease-out forwards;
    display: block;
    .mobilemenu-container {
      animation: fade-in .3s ease-out forwards;
      animation-delay: .3s;
      display: block;
    }
    .logo-container {
      animation: fade-in .3s ease-out forwards;
      animation-delay: .3s;
      display: block;
    }
  }
  .mobilemenu-container {
    position: relative;
    z-index: 2;
    opacity: 0;
    display: none;
  }
  @media (min-width: $screen-md-min) and (min-height: 650px) {
    display: none !important;
  };
  nav {
     ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          line-height: 1;
          padding: 14px 0;
          &.has-sub-menu {
            .icon {
              display: inline-block;
              font-size: 16px;
              margin-left: 8px;
              color: $color-four;
              transition: transform 300ms ease;
              vertical-align: middle;
              padding: 0 5px;
            }
            &.show-menu {
              > .icon {
                transform: rotate(-180deg);
              }
            }
            > a {
              padding-left: 34px;
            }
         }
         a {
           font-family: $headings-font-family;
           font-size: 24px;
           text-transform: uppercase;
           color: $color-one;
           letter-spacing: 0.8px;
           vertical-align: middle;
           span {
             vertical-align: middle;
           }
         }
         .sub-menu {
           display: none;
           li {
             a {
               font-size: 16px;
               color: $color-four;
             }
             &:first-child {
               padding-top: 28px;
             }
             &:last-child {
               padding-bottom: 0;
             }
             &.has-sub-menu {
               a {
                 &:after {
                   top: 5px;
                  }
                }
             }
           }
           .sub-menu {
             display: none;
             li {
               padding: 10px 0;
               &:first-child {
                padding-top: 32px;
              }
              a {
                span {
                  font-size: 14px;
                  color: $color-one;
                }
               }
             }
           }
         }
       }
     }
     .btn {
       display: inline-block;
       margin-top: 14px;
       padding: 13px 20px;
       a {
         font-size: 16px;
       }
     }
  }
  .btn-contact {
    z-index: 1;
    margin-top: 10px;
  }
}

.component-mailchimpinput {
  margin-top: 40px;
  .mc-field-group {
    position: relative;
    width: 100%;
    text-align: center;
    input {
      display: inline-block;
      color: $color-one;
      background: $white;
      border-radius: 5px;
      box-shadow: 0;
      border: 0;
      padding: 15px 20px;
      font-family: $headings-font-family;
      font-size: 16px;
      text-transform: uppercase;
      outline: 0;
      width: 100%;
      margin-bottom: 20px;
      @media(min-width: 600px) {
        max-width: 380px;
        margin-bottom: 0;
      }
      &::placeholder {
        color: $color-one;
      }
    }
    .component-button {
      display: inline-block;
      margin-left: 10px;
    }
    .btn {
      display: inline-block;
      color: $color-one;
      background: $color-two;
      font-size: 16px;
      width: auto;
      outline: 0;
      border-radius: 50px;
      @media (max-width: $screen-md-max) {
        padding: 15px 20px;
      }
    }
    div.mce_inline_error {
      position: absolute !important;
      top: 100% !important;
      left: 0 !important;
      background: none !important;
      color: $color-one !important;
      font-weight: normal !important;
      width: 100% !important;
      text-align: center !important;
      padding: 0px 10px !important;
      font-style: italic;
    }
  }
  #mce-responses div{
      margin-top: 0 !important;
      //position: absolute !important;
      top: 100% !important;
      left: 0 !important;
      background:  none !important;
      color: $color-two !important;
      font-weight: normal !important;
      width: 100% !important;
      text-align: center !important;
      padding: 5px 10px !important;
      font-style: italic;
      a {
        display: block;
        color: $color-one;
        font-weight: bold;
        font-style: italic;
      }
  }
}

.component-offerlist {
    @media (max-width: $screen-sm-max) {
        width: 100vw;
        margin: 0 -15px;
    }
    .offers-filters {
        padding: 0 15px;
        margin-bottom: 30px;
        select {
            width: 200px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-family: MBEmpire,sans-serif;
            font-size: 12px;
            font-weight: 700;
            color: #16b3aa;
            padding: 15px 12px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0;
            line-height: 1.4;
            height: auto!important;
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
            & + select {
                @media (min-width: $screen-sm-min) {
                    margin-left: 15px;
                }
                @media (max-width: $screen-xs-max) {
                    margin-top: 10px;
                }
            }
        }
    }
}
.component-office {
  margin: 0 -15px;

  & > div {
    margin: 0 15px;
  }

  &.cols-4, &.cols-3, &.cols-2 {
    & > div {
      margin-bottom: $margin;
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;

    &.cols-4, &.cols-3, &.cols-2 {
      & > div {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &.cols-4 {
      & > div {
        width: 25%;
      }
    }

    &.cols-3 {
      & > div {
        width: 33.333%;
      }
    }

    &.cols-2 {
      & > div {
        width: 50%;
      }
    }
  }

  a.mail {
    word-break: break-all;
  }

  .map {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    &.small {
      padding-bottom: 100%;
    }
  }

  .map-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
  }

  h5 {
    margin: 0 0 10px 0;
    font-size: 16px;
  }

  .position,
  .details {
    padding: 0;
    list-style: none;
    color: $gray;
    font-size: 16px;
    font-weight: 300;
  }

  p {
    margin-bottom: 0;
    color: $gray;
    font-size: 16px;
    font-weight: 300;
  }

  .details {
    margin-bottom: 0;
  }

  .details a {
    color: $gray;
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.component-pagetitle {
  .bg {
    display: table;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200px;
    width: 100%;

    @media (max-width: $screen-xs-max) {
      height: 100px;
    }
  }

  h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: $white;
  }
}

.component-postlist {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .pager {
    list-style: none;
    padding: 0;
    margin: 20px 0 40px 0;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-size: 16px;
    overflow: auto;
    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}
.post-notifications-count {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #F42929;
  color: $white;
  font-family: $headings-font-family;
  text-align: center;
  text-decoration: none;
}

.component-header__right {
  .post-notifications-count {
    position: absolute;
    top: -10px;
    left: 0;
  }
}

/*
 * Mark posts unread in the post list
 */
.component-post {
  .unread {
    @extend .post-notifications-count;
    display: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 2px;
  }

  .post-notifications-new {
    .unread {
      display: inline-block;
    }
  }
}

.component-postlatest, .component-postlist {
  @media (max-width: $screen-sm-max) {
    > .row {
      > div {
        & + div {
          margin-top: 20px;
        }
      }
    }
  }
  .image {
    position: relative;
    height: 375px;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    .author-date {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-family: $headings-font-family;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-align: right;
      span {
        display: block;
        color: $white;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 70%,rgba(0,0,0,0.3) 100%);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 30px;
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      transform: rotate(45deg);
    }
  }
  .inner {
    padding-bottom: 40px;
    .heading {
      display: block;
      margin-top: 35px;
      text-transform: uppercase;
      color: #000000;
      h4 {
        margin-bottom: 15px;
      }
    }
    .categories {
      display: block;
      margin-bottom: 20px;
      i {
        margin-left: 0;
        color: $color-five;
        font-size: 24px;
        vertical-align: middle;
        &:before {
          vertical-align: baseline;
        }
      }
      a {
        font-weight: 300;
        color: $gray;
        vertical-align: middle;
        font-family: $headings-font-family;
        text-decoration: underline;
        font-size: 18px;
        margin-left: 5px;
      }
      span {
        font-weight: 300;
        vertical-align: middle;
      }
    }
    .read-more {
      position: relative;
      display: inline-block;
      font-family: $headings-font-family;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;;
      margin-top: 25px;
      transition: all 300ms ease;
      &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        height: 2px;
        width: 0;
        background: $color-four;
        transition: width 300ms ease, color 300ms ease;
      }
      &:hover {
        i {
          transform: rotate(180deg);
          color: $color-one;
        }
        &:after {
          width: 100%;
          background: $color-one;
        }
      }
      i {
        display: inline-block;
        margin-left: 5px;
        color: $color-four;
        transition: all 300ms ease;
      }
    }
  }
}

.component-post.list {
  .row {
    display: flex;

    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }

  .read-more-image,
  .read-more-heading {
    display: block;
  }

  .row > div {
    align-items: stretch;
  }

  .image {
    position: relative;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px;;
    }
  }

  .unread {
    position: absolute;
    top: 10px;
    left: 10px;
    display: none;
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 6px;
    margin-bottom: -1px;
  }

  .post-notifications-new {
    .unread {
      display: inline-block;
      z-index: 2;
    }
  }

  .pattern {
    position: relative;
    width: 100%;
  }

  .pattern:before {
    content: "";
    display: block;
    padding-top: 56.25%;
    background: url(pattern.png) no-repeat 0 0;
    background-size: cover;
    background-color: $gray
  }

  .meta {
    font-size: 12px;
    font-weight: normal;
    color: $gray;
    margin-bottom: 10px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px;
    }

    a {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    .categories {
      a {
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none;
      }
    }

    .tags {
      display: inline-block;

      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    .date {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .excerpt {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 15px;

    @media (max-width: $screen-md-max) {
      font-size: 18px;
    }
  }

  .read-more {
    display: inline-block;
    margin-top: 15px;
    color: $blackish;
    line-height: 22px;
    font-size: 22px;
    font-weight: 300;
    vertical-align: top;

    @media (max-width: $screen-md-max) {
      font-size: 18px;
      line-height: 18px;
    }

    .icon {
      font-size: 18px;
      color: $placeholder;
    }

    &:hover {
      color: $placeholder;
    }
  }
}

.component-post.single {
  padding-top: 30px;
  .heading {
    display: block;
    text-transform: uppercase;
    color: #000000;
    h4 {
      margin-bottom: 25px;
    }
  }
  .categories, .date {
    display: inline-block;
    margin-bottom: 25px;
    @media (max-width: 550px) {
      display: block;
    }
    & + .categories, & + .date {
      margin-left: 20px;
      @media (max-width: 550px) {
        margin-left: 0;
      }
    }
    i {
      margin-left: 0;
      color: $color-five;
      font-size: 24px;
      vertical-align: middle;
      &:before {
        vertical-align: baseline;
      }
    }
    a, span {
      font-weight: 300;
      color: $gray;
      vertical-align: middle;
      font-family: $headings-font-family;
      text-decoration: underline;
      font-size: 18px;
      margin-left: 5px;
    }
    span {
      font-weight: 300;
      vertical-align: middle;
      text-decoration: none;
    }
  }
  .image {
    padding-top: 56.25%;
    margin-bottom: 25px;
    background-size: cover;
  }
  .content {
    h1, h2, h3, h4, h5 {
      font-size: 24px;
      a {
        color: inherit;
        font-weight: bold;
      }
    }
    h1, h2, h3, h4, h4, p {
      text-align: left !important;
    }
  }
  .author {
    display: flex;
    padding: 15px 0;
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .author-image {
      flex: 0 0 auto;
      margin-right: 15px;
      @media (max-width: 550px) {
        margin-bottom: 15px;
        margin-right: 0;
      }
      img {
        width: 85px;
        height: 85px;
        border: 3px solid $color-one;
        border-radius: 50%;
      }
    }
    .content {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .name, .title, .email {
        display: block;
        margin: 1px 0;
      }
      .name {
        font-family: $headings-font-family;
        font-weight: bold;
        text-transform: uppercase;
        color: $black;
      }
      .email {
        text-decoration: underline;
        color: $gray;
        font-weight: 300;
      }
    }
  }
  .pager {
    list-style: none;
    padding: 0;
    margin: 40px 0 40px 0;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-size: 16px;
    overflow: auto;
    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-post {

  .date {
    text-transform: lowercase;
  }

  &.single {

    img {
      width: 100%;
      height: auto;
    }

    .next-post {
      float: right;

      span {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      .component-image {
        margin-bottom: $margin * 2;
      }
    }

    @media (max-width: $screen-sm-max) {
      .meta {
        margin-bottom: $margin;
      }
    }
  }

  .date, .category, .author {
    color: $gray-darker;
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  .category a {
    font-weight: $font-weight-base;
  }

  .catdate {
    @media (max-width: $screen-md-max) {
      margin-bottom: 10px;
    }
  }
}

.component-post.list {
  overflow: hidden;
  width: 100%;
  @extend %clearfix;

}

.component-post--list__image {
  width: 40%;
  height: 350px;
  float: left;
  display: block;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 100%;
    height: 250px;
  }
}

.component-post--list__body {
  padding: 35px;
  width: 60%;
  float: left;
  background-color: $gray;
  position: relative;
  height: 350px;

  @media (max-width: 500px) {
    width: 100%;
    height: auto;
  }

  &.full {
    width: 100%;
  }

  .meta, h2 {
    margin-bottom: 15px;
  }

  span {
    margin-right: 15px;
  }

  p {
    margin-bottom: 0;
    display: block;
    @include font-size($ingress-font-sizes);
  }

  .title-link {
    overflow: hidden;
    display: inline-block;
  }

  .read-more {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 60px solid $link-color;
    border-left: 100px solid transparent;

    .icon {
      margin-right: 0;
      color: $white;
      position: absolute;
      bottom: -50px;
      right: 10px;
      font-size: 20px;
    }
  }
}
.component-postlatest {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.component-quote {
  @include clearfix;
  transform-style: preserve-3d;

  .image {
    width: 35%;
    height: auto;
    float: left;

    @media (max-width: 870px) {
      display: none;
    }
  }

  &.has-image .wrapper {
    @media (min-width: 870px) {
      position: absolute;
      left: 30%;
      right: 0;
      max-width: 600px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 870px) {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 50px;
      padding-right: 30px;
    }
  }

  &.no-image .wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 70px;
    padding-right: 30px;

    @media (max-width: $screen-xs-max) {
      padding-left: 50px;
    }
  }

  .quote {
    font-size: 24px;
    line-height: 34px;
    position: relative;
    z-index: 1;

    @media (max-width: 1245px) {
      font-size: 18px;
      line-height: 24px;
    }

    &:before {
      @include icomoon;
      content: $icon-quote;
      position: absolute;
      color: $color-one;
      z-index: -1;
      opacity: 0.5;
      font-size: 100px;
      left: -50px;
      top: -20px;

      @media (max-width: 1245px) {
        font-size: 70px;
      }

      @media (max-width: 870px) {
        font-size: 50px;
        left: -20px;
        top: -12px;
      }
    }
  }

  &.no-quote .quote:before {
    display: none;
  }

  .name {
    font-family: $font-family-bold;
    text-transform: uppercase;
  }
}

.component-row .section .component-recurrentcontent .component-row {
  margin-top: 0;
}
.component-reccurentcontentslider {
  .tin-slide {
    position: relative;
  }
  nav.tin-slide-next-prev {
    .tin-slide-next, .tin-slide-prev {
      position: absolute;
      top: calc(50% - 20px);
      height: 40px;
      width: 40px;
      line-height: 1;
      &:hover {
        &:before {
          opacity: 1;
          transform: scale(1.05);
        }
      }
      &:before {
        display: inline-block;
        width: 50px;
        height: 50px;
        font-family: 'icomoon';
        font-size: 40px;
        color: white;
        opacity: 0.6;
        transition: all 300ms ease;
      }
    }
    .tin-slide-next {
      right: 0px;
      &:before {
        content: $icon-arrow-right-circle;
      }
    }
    .tin-slide-prev {
      left: 0px;
      &:before {
        content: $icon-arrow-left-circle;
      }
    }
  }
}

.component-row {
  position: relative;
  overflow-x: hidden;

  .header-overlay {
    display: none;
    body.home &, body.page-id-12033 & {
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 180px;
      background: linear-gradient(180deg,rgba(0,0,0,.2) 0,rgba(0,0,0,.1) 74%,transparent);
      pointer-events: none;
      @media (min-width: $screen-md-min) {
        background: linear-gradient(180deg,rgba(0,0,0,.3) 0,rgba(0,0,0,.1) 74%,transparent);
      }
    }
  }

  .pattern-1 {
    background-image: url('pattern_beach_liggandes.svg');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: -1px;
    left: -1px;
    bottom: 0;
    overflow: hidden;
  }

  .section {
    &.has-slider {
      display: flex;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(1,2,2,0.2) 1%,rgba(125,185,232,0) 100%);
  }

  .home-overlay {
    display: none;
    body.home &, body.page-id-12033 & {
      display: block;
    }
  }

  .row-wrapper {
    position: relative;
    overflow-y: hidden;
  }

  &.height-large .row-wrapper {
    min-height: 650px;

    @media (max-width: $screen-xs-max) {
      min-height: 500px;
    }
  }

  &.height-medium .row-wrapper {
    min-height: 330px;
  }

  &.height-full .row-wrapper {
    min-height: 100vh;
  }

  &.height-small .row-wrapper {
    min-height: 200px;
  }

  .row-wrapper {
    &.padding {
      &--md {
        padding-top: 180px;
        padding-bottom: 180px;
        @media(max-width: $screen-md-max) {
          padding-top: 150px;
          padding-bottom: 150px;
        }
        @media(max-width: $screen-tablet) {
          padding-top: 120px;
          padding-bottom: 120px;
        }
      }
      &--sm {
        padding-top: 60px;
        padding-bottom: 60px;
        @media(max-width: $screen-md-max) {
          padding-top: 40px;
          padding-bottom: 40px;
        }
        @media(max-width: $screen-tablet) {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
      &--xl {
        padding-top: 260px;
        padding-bottom: 260px;
        @media(max-width: $screen-md-max) {
          padding-top: 200px;
          padding-bottom: 200px;
        }
        @media(max-width: $screen-tablet) {
          padding-top: 150px;
          padding-bottom: 150px;
        }
      }
    }
  }

  .section {
    position: relative;
    width: 100%;

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      overflow-x: hidden;
    }

    .component-row {
      //margin-top: $margin * 2.5;

      @media (max-width: $screen-xs-max) {
        //margin-top: $margin;
      }
    }

    .container-fluid .row.inner {
      margin-bottom: $margin * 1.5;

      .col-sm-12:first-of-type {
        margin-bottom: $margin;
      }
    }
  }

  .container-fluid.full-width {
    @media (min-width: 1275px) {
      padding: 0;
    }

    .row-inner {
      margin-left: 0;
      margin-right: 0;
    }

    .vc_row {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.no-column-padding {
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .wpb_column {
        padding: 0 !important;
      }
    }
  }

  &.bkg-blend {
    .bg-image{
      opacity: 0.3;

      @supports (mix-blend-mode: multiply) {
        opacity: 1;
        background-blend-mode: luminosity;
        mix-blend-mode: multiply;
        filter: grayscale(100%);
      }
    }
  }

}

.component-row .container .component-row:last-of-type {
  .row.inner {
    margin-bottom: 0;
  }
}

.container-fluid .row.inner .col-sm-12 {
  margin-bottom: $margin;
}

.component-share {
  .wrapper {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    margin: 20px 0;
    padding: 20px 0;
  }

  .addthis_toolbox a {
    margin: 0;
  }
}

.component-searchresult {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5rem;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100%;

  .search-container {
    margin-bottom: 80px;
    position: relative;
  }

  #search-input{
    border: 0 solid;
    box-sizing: border-box;
    font-family: MBEmpire,sans-serif;
    margin: 0;
    outline-offset: -2px;
    background-color: rgb(255,255,255);
    border-bottom-width: 2px;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.25;
    width: 100%;
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
    padding-left: 10px;
    height: 2.25rem;
  }

  .search-right-box {
    border: 0 solid;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 4.5rem;
    display: flex;
    width: 5rem;
    align-items: center;

    .reset{

      border: 0 solid;
      box-sizing: border-box;

      border-color: currentColor;
      margin: 0;
      background-color: transparent;
      background-image: none;
      cursor: pointer;
      padding: 0;
      font-family: 'Material Icons';
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      margin-right: 1.25rem;
      align-items: center;
      color: var(--input);
    }

    .search-button-div{
      background-color: #0e3535;
      color: #f4d0c6;
      padding:5px;
      border-radius: 5px;

      .search-button{
        background-color: transparent;
        border: none;
        .search-text{
          font-family: MBEmpire,sans-serif;
        }
      }

    }

  }
  article{
    margin-bottom: 64px;
    border-bottom: 1px solid;
    h2{
      text-transform:none;
    }
    a{
      color: #0e3535;
    }
    a:hover{

    }
  }

  .search-result{
    .search-result-image{
      display: inline-block;
      width: 20%;
      vertical-align: top;
      margin-top: 10px;
      margin-right: 25px;
    }
    .search-result-text{
      display: inline-block;
      width: 70%;
      margin-bottom:80px;
      border-bottom-width: 1px;
      p a:last-of-type {
        color: #16b3aa;
      }
    }
  }
  .search-boxes{
    display:flex;
    gap: 20%;
    .search-box{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #ccc;
      box-sizing: border-box;
      .search-box-image{
        height: 160px;
        width: 233px;
      }
      .search-box-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .search-box-text{
        padding-left: 10px;
        margin-top: auto;
        vertical-align: bottom;
        h5{
          text-transform:none;
        }
      }
    }
  }
}




.component-sidemenu {
  padding-top: 5px;

  ul, li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    border-bottom: 1px solid $gray-light;
  }

  a {
    display: block;
    padding: 20px 40px 20px 20px;
    position: relative;
    color: $placeholder;
    font-size: 18px;

    .icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -10px;
    }

    &:hover {
      background-color: $gray-lighter;
      color: $placeholder;
    }
  }

  li.active > a {
    background-color: $gray-lighter;
  }

  /**
   *  Sub menu.
   */
  ul.sub-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms;

    li {
      border-bottom: none;
      border-top: 1px solid $gray-light;
    }

    a {
      padding-left: 30px;
      font-size: 14px;
    }
  }

  li.has-sub-menu > a .icon {
    cursor: default;
    transform: rotate(90deg);
    transition: transform 400ms;
  }

  li.sub-menu-on > a .icon {
    transform: rotate(-90deg);
  }
}

.component-sidebar {
  list-style: none;

  @media (max-width: $screen-sm-max) {
    margin-bottom: $margin;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    color: $black;
    margin-bottom: 15px;
    @media (max-width: $screen-sm-max) {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  li {
    a {
      color: $gray;
      font-size: 18px;
      font-family: $headings-font-family;
    }
  }

  @media (max-width: $screen-sm-max) {
    .group-ipad {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      li {
        width: 50%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    color: $blackish;
    text-decoration: underline;
    text-transform: capitalize;
  }

  a:hover {
    text-decoration: none;
  }
}

.component-slider {
  .slide {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    justify-content: center;

    &:focus,
    &:active {
      outline: none;
    }

    &.full {
      min-height: calc(100vh - 126px);
    }

    &.large {
      min-height: 580px;

      @media (max-width: $screen-xs-max) {
        min-height: 450px;
      }
    }

    &.medium {
      min-height: 380px;

      @media (max-width: $screen-xs-max) {
        min-height: 300px;
      }
    }

    &.small {
      min-height: 150px;
    }
  }

  .content-wrapper {
    text-align: center;

    .content-inner {
      display: inline-block;
      max-width: 75%; //TODO Variate on screen sizes
    }

    .component-heading {
      h1 {
        @include font-size((
          null: 30px,
          small: 40px,
          medium: 52px,
          large : 62px,
        ));
      }
    }

    .btn-wrapper {
      display: inline-block;
    }
  }

  .bg-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .icon-double-down {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: 20px;
    font-size: 50px;
    color: #FFF;
    cursor: pointer;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .btn-wrapper .float-btn {
    @media (max-width: $screen-xs-max) {
      float: none;
    }
  }

  /**
   *  Video slide.
   */
  .slide.video {

    iframe {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .play-button {
      cursor: pointer;
      width: 75px;
      height: 75px;
      @media screen and (min-width: $screen-sm-min) {
        width: 147px;
        height: 146px;
      }

      .icon {
        font-size: 146px;
        color: $white;
        transition: color 0.2s;
      }

      &:hover {
        .icon {
          color: $placeholder;
        }
      }
    }

    .youtube-slide-close {
      display: none;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;

      .icon {
        font-size: 35px;
        color: $white;
        transition: color 0.2s;
      }

      &:hover {
        .icon {
          color: $placeholder;
        }
      }
    }

    &.video-on {
      iframe, .youtube-slide-close {
        display: block;
      }
    }

    .video-content {
      .play-button {
        display: inline-block;
      }
      .headings {
        display: none;
        @media screen and (min-width: $screen-sm-min) {
          display: inline-block;
        }
      }
      @media screen and (min-width: $screen-sm-min) {
        font-size: 0;
        .play-button {
          vertical-align: middle;
          margin-right: -147px;
        }
        .headings {
          vertical-align: middle;
          margin-left: 147px + 20px;
          max-width: 800px;
        }
      }
    }

  }

  /**
   *  Simple slide with image and headings.
   */
  .simple-slide {
    outline: 0;
    // .row-wrapper {
    //   display: flex;
    //   align-items: center;
    // }
    // .container {
    //   // margin-left: 0;
    //   // margin-right: 0;
    //   // @media screen and (min-width: $screen-sm-min) {
    //   //   margin-left: auto;
    //   //   margin-right: auto;
    //   // }
    // }
    h5 {
      text-transform: uppercase;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 16px;
    text-align: center;
    z-index: 9;

    li {
      background-color: transparent;
      border: 2px solid $gray-light;
      width: 16px;
      height: 16px;
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        cursor: auto;
      }

      &.slick-active:before {
        border: 2px solid $placeholder;
        background-color: $placeholder;
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: 50%;
      }
    }
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 10;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 60px;
      content: $icon-chevron-left;
      color: $white;
      line-height: 60px;
    }

    &:hover:before {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    &.slick-next {
      right: 30px;

      &:before {
        content: $icon-chevron-right;
      }
    }

    &.slick-prev {
      left: 30px;
    }

    @media (max-width: $screen-sm-max) {
      width: 30px;
      height: 30px;

      &:before {
        line-height: 30px;
      }
    }
  }
}

.component-submenu {
  background-color: $gray;
  padding-top: $gap;
  transition: top 0.3s ease-in-out;

  &.headroom--not-top {
    @media (max-width: $screen-xs-max) {

      &.headroom--unpinned {
        top: 0;
      }

      &.headroom--pinned {
        width: 100%;
        left: 0;
        position: fixed;
        z-index: $banner-zindex;
        top: 60px;
      }
    }
  }

  > .container {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      width: 100%;
    }
  }

  .navigation {
    float: none;
  }

  ul {
    width: 100%;
    @include clearfix;
  }

  li {
    width: 10%;
  }

  li:nth-last-child(1):first-child,
  li:nth-last-child(1):first-child ~ li {
    width: 100%;
  }

  li:nth-last-child(2):first-child,
  li:nth-last-child(2):first-child ~ li {
    width: (100% / 2);
  }

  li:nth-last-child(3):first-child,
  li:nth-last-child(3):first-child ~ li {
    width: (100% / 3);
  }

  li:nth-last-child(4):first-child,
  li:nth-last-child(4):first-child ~ li {
    width: (100% / 4);
  }

  li:nth-last-child(5):first-child,
  li:nth-last-child(5):first-child ~ li {
    width: (100% / 5);
  }

  li:nth-last-child(6):first-child,
  li:nth-last-child(6):first-child ~ li {
    width: (100% / 6);
  }

  li:nth-last-child(7):first-child,
  li:nth-last-child(7):first-child ~ li {
    width: (100% / 7);
  }

  a {
    height: 70px;
    position: relative;
    transform-style: preserve-3d;

    span {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 10px;

      @media (min-width: $screen-md-min) {
        padding: 0 20px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 40px;
      background: $gray-darker;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  li:last-of-type a:after {
    display: none;
  }

  li.active {
    margin-left: -1px;

    a {
      background-color: $white;

      &:after {
        background: $white;
      }
    }
  }

  .navigation-select {
    display: block;
    padding: $margin;
    color: $white;
    font-size: 20px;
    line-height: 20px;
    font-weight: $font-weight-medium;
    letter-spacing: 0.5px;
    cursor: pointer;

    &.active {
      span {
        &:before {
        }
      }
    }

    span {
      float: right;
    }
  }

  @media (max-width: $screen-xs-max) {
    background-color: $brand-primary-light;
    padding-top: 0;

    .container {
      padding: 0;
    }

    .navigation {
      display: none;
    }

    li {
      width: 100% !important;
    }

    li:first-child {
      border-top: 1px solid $white;
    }

    li.active {
      margin-left: 0;

      a {
        background-color: $brand-primary;
      }
    }

    a {
      color: $white;
      height: 50px;

      span {
        padding: 0 20px;
        text-align: left;
      }

      &:after {
        display: none;
      }
    }
  }
}
.component-teaser {
  .image {
    position: relative;
    height: 375px;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 30px;
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      transform: rotate(45deg);
    }
  }
  .inner {
    padding-bottom: 20px;
    .heading {
      display: block;
      margin-top: 35px;
      text-transform: uppercase;
      color: #000000;
    }
    .component-button {
      margin-top: 25px;
    }
  }
}

.component-tabbar {
  z-index: 5000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  display: none;

  &:before {
    content: "";
    background-color: $white;
    height: 2px;
    left: 0;
    right: 0;
    top: -5px;
    position: absolute;
  }

  @media (max-width: $screen-xs-max) {
    display: block;
  }

  li {
    width: 25%;
    float: left;
  }

  li.active a:after {
    background-color: $brand-primary;
  }

  li.active a {
    color: $brand-primary;
  }

  .menu-item.contact a:before {
    content: $icon-chevron-down;
  }

  .menu-item.house a:before {
    content: $icon-chevron-down;
  }

  .menu-item.news a:before {
    content: $icon-chevron-down;
  }

  .menu-item.search a:before {
    content: $icon-chevron-down;
  }

  .menu-item:last-child a {
    margin-right: 0;
  }

  a {
    height: 50px;
    color: #757986;
    position: relative;
    text-align: center;
    margin-right: 2px;
    z-index: 1;

    &:after {
      content: "";
      background-color: #757986;
      height: 3px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    &:before {
      @include icomoon;
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
    }
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    margin-left: auto;
    line-height: 1;
    margin-right: auto;
    font-size: 12px;
  }
}

.component-teaserbackgroundimage {
  position: relative;
  overflow: hidden;
  margin-bottom: $margin;
  background-color: $black;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  .teaser-link {
    &:hover {
      .image {
        //transform: scale(1.1);
      }
    }
  }
  @each $name, $color in $colors-map {
    &.bg-#{$name} {
      background: map-get($color, "background");
    }
  }
  .image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform 1.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 100%);
      opacity: 0.3;
    }
  }
  .pattern {

  }

  .wrapper {
    padding: 70px 30px;
    min-height: 360px;
    text-align: left;
    position: relative;
    justify-content: center;
    height: 100%;
    display: flex;
    .content {
      width: 100%;
    }
    .read-more {
      position: absolute;
      bottom: 20px;
      left: 30px;
      display: inline-block;
      color: $white;
      font-family: $headings-font-family;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 300ms ease;
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        width: 0;
        background: $white;
        transition: width 300ms ease;
      }
      i {
        display: inline-block;
        margin-left: 7px;
        transition: transform 300ms ease;
      }
    }
  }
  .small-heading {
    position: absolute;
    top: 20px;
    left: 30px;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    font-family: $headings-font-family;
    text-transform: uppercase;
  }
  .heading {
    margin-bottom: 10px;
    color: $white;
    line-height: 1.3;
    text-transform: none;
    word-break: break-word;
    u {
      text-decoration: none;
    }
  }

  /* Hover effects */
  &:hover {
    .heading {
      u {
        box-shadow: inset 0 0 0 $white, inset 0 -4px 0px $white;
      }
    }
    .read-more {
      opacity: 1;
      &:after {
        width: 100%;
      }
      i {
        transform: rotate(180deg)
      }
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .column-vertical-stretch .component-teaserbackgroundimage {
    height: 100%;

    .image-wide, .image-square {
      height: 100%;
      padding-top: 0;

      .wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.wpb_text_column {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul {
    padding-left: 0;

    li {
      padding-left: 20px;
      position: relative;
      list-style-type: none;
      font-family: $font-family-demi;
      margin-bottom: 10px;

      &:before {
        content: ' ';
        display: inline-block;
        width: 5px;
        height: 5px;
        background: $text-color;
        border-radius: 50%;

        position: absolute;
        top: 11px;
        left: 3px;
      }
    }
  }

  img {
    max-width: 80%;
    height: auto;
  }

  @media (min-width: $screen-xs-max) {
    img {
      max-width: 100%;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.component-video {
  overflow: hidden;
  position: relative;
  margin-bottom: $margin;
  cursor: pointer;

  .icon {
    font-size: 60px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all $transitionTime $easeCurve;
    background: none;
    border-radius: 50%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    pointer-events: none;
    color: $color-two;
    &:hover {
      font-size: 70px;
      line-height: 70px;
      height: 70px;
      width: 70px;
    }
  }
}

.fancybox-skin {
  border-radius: 0;
}

.video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  background-size: cover;
  background-position: center;
}

.youtube-player {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover {
    + .icon {
      font-size: 70px;
      line-height: 70px;
      height: 70px;
      width: 70px;
    }
  }

  > div {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba($black, 0.15);
  }
}

.youtube-player iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

body.single-villa {



  .features {
    background: $gray-lighter;
    display: block;
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 60px;
    }

    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding: 10px;

      li {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        color: $black;
        white-space: nowrap;

        .icon {
          margin-right: 10px;
          color: $gray;
        }
      }

    }
  }

  .descr {
    text-align: center;
  }

  .location-usps {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    flex-wrap: wrap;

    .usp {
      display: flex;
      align-items: center;
      white-space: nowrap;
      display: inline-block;
      margin: 10px 20px;

      .icon {
        color: $color-five;
      }
    }
  }

  .highlighted-facts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .fact {
      background: $brand-primary;
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      margin: 20px;


      .number {
        color: $color-five;
        display: inline-block;
        font-size: 70px;
        line-height: 1;
      }
      .text{
        display: inline-block;
        color: $color-four;
        text-transform: uppercase;
        font-size: 16px;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
      }

      @media (min-width: $screen-md-min) {

        height: 200px;
        width: 200px;

        .number {
          font-size: 85px;
        }
      }



    }

  }

  .image-gallery {
    padding: 50px 0;
  }

  .in-brief {
    margin-bottom: 50px;
    .usp {
      display: block;
    }
  }

  .acommodation,
  .facilities{
    margin-bottom: 50px;

    .item {
      margin-bottom: 30px;
      .title {
        text-transform: uppercase;
        margin: 0 0 10px;
        font-weight: $font-weight-semibold;
      }

      .info {
        display: block;
        margin-bottom: 3px;
        position: relative;

        &.has-tooltip {
          .icon {
            margin-left: 5px;
            display: inline-block;
            color: $color-five;
          }
        }

      }
    }
  }

  .important-info,
  .about{
    margin-bottom: 50px;
      h1,h2,h3,h4,h5:not(.color-black) {
        font-size: 19px;
        font-family: $font-family-base;
        font-weight: $font-weight-semibold;
        color: $text-color;
      }

  }

  .reviews {
    h2 {
      width: 100%;
      padding: 100px 0;
      background: $gray-lighter;
      text-align: center;
      color: $gray-light;
    }


  }


  .side {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 20px;


    .booking-calendar {
      width: 100%;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
      background: $white;
      padding: 15px 10px;
      display: block;
      transition: box-shadow 0.3s ease;

      &.focus {
        box-shadow: 0 3px 30px rgba(0, 0, 0, 0.4);
      }

      .price {
        text-align: center;
        color: $brand-primary;
        font-family: $headings-font-family;
        letter-spacing: -.03em;

        span {
          font-size: 120%;
          font-weight: bold;
        }
      }

      .phone-html {
        padding: 0 !important;
        width: 100% !important;
      }

      .phone {
        margin-top: 50px;

        a {
          background: $color-five;
          padding: 10px 0;
          color: #fff;
          display: block;
          text-align: center;
          font-size: 80%;
          font-weight: normal;
          font-family: $headings-font-family !important;
          letter-spacing: -0.04em;
          border-radius: 50px;
          transition: background 0.3s ease;

          &:hover {
            background: $color-five-hover;
          }
        }
      }

      .form {

        .gform_wrapper {
          margin:0 !important;
        }

      .gform_body {
        max-width: 620px;
        margin: 0 auto;

        .gsection_title {
          display: none;
        }

        .gsection + .gfield {
          margin-top: 0;
        }

        @media (min-width: $screen-sm-min) {
          .gsection + .gfield:not(.fullwidth) + .gfield:not(.fullwidth) {
            margin-top: 0;
          }
        }

        .gfield {
          width: 100%;
          float: left;
          clear: initial !important;
          padding-left: 10px;
          padding-right: 10px;
          @media (min-width: $screen-sm-min) {
            width: 50%;
          }

          &.gsection {
            width: calc(100% - 20px);
            margin-left: 10px !important;
          }

          &.full-width {
            width: 100%;
          }

          .instruction {
            display: none;
          }

          .ginput_container_checkbox label {
            padding-right: 10px;
            margin: 0 auto;
            text-align: center;
          }

          .ginput_container {
            position: relative;
          }
        }

        .gform_footer {
          text-align: center;
        }

        label {
          display: block;
          font-family: $headings-font-family;
          font-size: 12px;
          font-weight: bold;
          color: $color-one;
          text-align: left;
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        input[type="text"], .datepicker, textarea, select {
          width: 100%;
          background: $white;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          font-family: $headings-font-family !important;
          font-size: 12px !important;
          font-weight: bold;
          color: $color-four;
          padding: 15px 12px !important;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: 0;
          line-height: 1.4;
          height: auto !important;
          @media (max-width: $screen-sm-max) {
            &:focus {
              font-size: 16px;
            }
          }

          &.error {
            background: $color-two;
            color: $color-one;
          }
        }

        .datepicker {
          font-family: $headings-font-family !important;
          font-size: 12px !important;
          font-weight: bold !important;
          color: $color-four !important;

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-family: $headings-font-family;
            font-size: 12px;
            font-weight: bold;
            color: $color-four;
            opacity: 0.5; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: $headings-font-family;
            font-size: 12px;
            font-weight: bold;
            color: $color-four;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-four;
          }
        }

        .btn {
          font-size: 12px;
          @media (min-width: 1380px) {
            font-size: 14px;
          }
          @media (min-width: 1501px) {
            font-size: 16px;
          }
        }

        .form-error-text {
          font-family: $headings-font-family;
          font-size: 14px;
          text-transform: uppercase;
          color: $color-five;
          @media (max-width: 1100px) {
            padding-bottom: 30px;
          }
        }

        .form-loading {
          padding-top: 10px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: $headings-font-family;
          text-transform: uppercase;
          color: $color-one;
          @media (max-width: 1100px) {
            padding-bottom: 30px;
          }
        }
      }

      .form-confirm {
        width: 100%;
        min-height: 400px;
        background: $color-one;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 10px -10px -10px;
        width: calc(100% + 20px);

        p {
          text-align: center;
          color: $white;
          font-family: $headings-font-family;
          font-size: 18px;
          letter-spacing: -0.04em;
        }

      }

      input[type="submit"] {
        outline: 0;
        width: 100%;
        &:hover {
          background: $color-one-hover;
        }
      }
    }








    }

  }

  #ui-datepicker-div {
    display: none !important;
  }

}
.component-villaarchive {
  display: block;

  .top-area {
    margin-bottom: 50px;
    background-color: $brand-primary;
    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);


    .row {
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 300px;
      flex-direction: column;

      @media(min-width: $screen-md-min) {
        height: 500px;
      }
    }

    .location {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      font-style: italic;
      color: #fff;
      margin-bottom: 10px;
      text-transform: none;
      z-index: 1;
    }

  }

  .villas-banner-location{
    font-size: 38px;
  }


  .villa-row {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;

    .featured-image {
      background-color: $brand-primary;
      background-size: cover;
    }

  }

  .intro-text {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
  }

  .list-title {
    &.has-sub-section {
      margin-top: 75px;
    }
  }

  .hotels-container {
    padding-bottom: 50px;

    .hotel-item {
      padding-bottom: 30px;
    }

    .read-more,
    .coh-resp-img{
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .meta {
      background: $gray-lighter;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      div {
        padding: 10px;
        font-family: $headings-font-family;
        color: $text-color;
        font-size: 14px;


        i {
          margin-right: 3px;
        }
      }
    }

    .in-brief {
      margin-top: 10px;
      span {
        display: inline-block;
        color: $gray;
        position: relative;
        padding: 5px 10px;
        font-size: 90%;
      }
    }
  }


}

// Styles set in continentlist component

.component-villacountrylist {
  .featured-image{
    background-size: cover;
  }
}